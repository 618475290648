import React from 'react';
import CardList from './CardList/CardList';

export default function Main({ cards, setCards, preloader, 	products }) {
	return (

		<main className="main">
			<CardList
				cards={cards}
				setCards={setCards}
				preloader={preloader}
				products={products}
			/>
		</main>

	)
}