import React from 'react';
import {CONTACTS_CITY, CONTACTS_STREET, MAP_CITY, MAP_STREET} from "../../utils/constants";

export default function Contacts() {

	const divStyle = {
		position: 'relative',
		overflow: 'hidden',
	};
	const aStyleCity = {
		color: '#eee',
		fontSize: '12px',
		position: 'absolute',
		top: '0px',
	};
	const aStyleStreet = {
		color: '#eee',
		fontSize: '12px',
		position: 'absolute',
		top: '14px',
	};
	const iframeStyle = {
		position: 'relative',
	};


	return (

		<main className="content">
			<section className="map">
				<div style={divStyle}>
					<a href={MAP_CITY} style={aStyleCity}>{CONTACTS_CITY}</a>
					<a href={MAP_STREET} style={aStyleStreet}>{CONTACTS_STREET}</a>
					<iframe src="https://yandex.ru/map-widget/v1/-/CCUfEORV~A" className="map__location" allowFullScreen="true" style={iframeStyle}></iframe>
				</div>
			</section>
			<section className="info">
				<ul className="table">
					<li className="table__cell">
						<h4 className="table__heading">Телефон</h4>
						<p className="table__text">+7 (995) 677-65-72</p>
					</li>
					<li className="table__cell">
						<h4 className="table__heading">E-mail</h4>
						<p className="table__text">info@boty.shoes</p>
					</li>
					<li className="table__cell">
						<h4 className="table__heading">Адрес</h4>
						<p className="table__text">г. Екатеринбург ул. Антона Валека д. 15 офис 502</p>
					</li>
				</ul>
			</section>
			<section className="message">
				<h2 className="message__heading">Напишите нам</h2>
				{/*<form action="" className="form">*/}
				{/*	<input type="text" className="form__input" placeholder="Имя"/>*/}
				{/*	<input type="text" className="form__input" placeholder="E-mail"/>*/}
				{/*	<input type="text" className="form__input" placeholder="Телефон"/>*/}
				{/*	<input type="text" className="form__input" placeholder="Сообщение"/>*/}
				<form className="form" action="https://wa.me/79956776572" method="get" target="_blank">
					<button className="form__button">Написать</button>
				</form>
			</section>
		</main>

	)
}