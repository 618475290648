import React from 'react';

export default function Footer() {
	return (
		<footer className="footer">
			<nav className="footer__column-links">
				<a href="https://www.tinkoff.ru/rm/serebrennikova.kristina21/MX0Ve93385" className="footer__link-pay" target="_blank"></a>
				<a href="https://vk.com/serebrennikova_perfume" className="footer__link-vk" target="_blank"></a>
				<a href="https://instagram.com/boty_shoes?igshid=YmMyMTA2M2Y=" className="footer__link-insta" target="_blank"></a>
				<a href="https://t.me/boty_shoes" className="footer__link-telegram" target="_blank"></a>
				<a href=" https://wa.me/79956776572" className="footer__link-whatsapp" target="_blank"></a>
				<a href="tel:+79956776572" className="footer__link-phone"></a>
			</nav>
			<div>
				<p className="footer__copyright">&copy; 2022. BOTY shoes</p>
				<div className="footer__create-text">Создание сайта —
					<a className="footer__create" href="https://t.me/dgatalsky" target="_blank" title="Создание и продвижение сайтов и интернет-магазинов"> @dgatalsky</a>
				</div>
			</div>

		</footer>
	)
}