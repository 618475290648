import React, { useState, useEffect } from 'react';
import Card from '../Card/Card';
import Preloader from '../../Preloader/Preloader';

export default function CardList({ cards, setCards, preloader, category, products }) {
  const [shuffledCards, setShuffledCards] = useState([]);

  useEffect(() => {
    const filteredCards = category !== undefined ? products.filter(card => card.category === category) : products;

    // Проверяем, есть ли порядок карточек в локальном хранилище, и используем его, если есть
    const storedShuffledCards = JSON.parse(localStorage.getItem('shuffledCards'));
    if (storedShuffledCards && storedShuffledCards.length === filteredCards.length) {
      setShuffledCards(storedShuffledCards);
    } else {
      const newShuffledCards = shuffleArray(filteredCards);
      setShuffledCards(newShuffledCards);
      // Сохраняем порядок карточек в локальное хранилище
      localStorage.setItem('shuffledCards', JSON.stringify(newShuffledCards));
    }
  }, [category, products]);

  // Функция для перемешивания массива случайным образом
  const shuffleArray = (array) => {
    let currentIndex = array.length, randomIndex, temporaryValue;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };

  return (
    <section className="cardlist">
      {preloader && <Preloader isOpen="preloader" />}
      {shuffledCards.map((card, index) => (
        <Card
          key={index}
          product={card}
          cards={cards}
          setCards={setCards}
        />
      ))}
    </section>
  );
}