import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

export default function Card({ product, cards, setCards }) {
  function handleClick() {
    setCards(product);
  }

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [hovered, setHovered] = useState(false);
  const cardRef = useRef(null);
  let interval; // Define the interval variable here

  useEffect(() => {
    const handleScroll = () => {
      if (
        cardRef.current &&
        window.innerWidth <= 767 &&
        isElementInViewport(cardRef.current)
      ) {
        interval = setInterval(() => {
          setCurrentImageIndex((prevIndex) => (prevIndex + 1) % 4);
        }, 1500); // Change image every 1.5 seconds (adjust this as needed)
      } else {
        clearInterval(interval);
      }
    };

    if (window.innerWidth > 767) {
      if (hovered) {
        interval = setInterval(() => {
          setCurrentImageIndex((prevIndex) => (prevIndex + 1) % 4);
        }, 1500); // Change image every 1.5 seconds (adjust this as needed)
      } else {
        clearInterval(interval);
      }

      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      clearInterval(interval);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hovered]);

  useEffect(() => {
    if (window.innerWidth <= 767) {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            interval = setInterval(() => {
              setCurrentImageIndex((prevIndex) => (prevIndex + 1) % 4);
            }, 1500); // Change image every 1.5 seconds (adjust this as needed)
          } else {
            clearInterval(interval);
          }
        });
      }, options);

      if (cardRef.current) {
        observer.observe(cardRef.current);
      }

      return () => {
        clearInterval(interval);
        if (cardRef.current) {
          observer.unobserve(cardRef.current);
        }
      };
    }
  }, []);

  const isElementInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  return (
    <figure
      className="cards__item"
      onClick={handleClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      ref={cardRef}
    >
      <Link to={`/product/${product.id}`} className="cards__img">
        <img
          src={product[`img${currentImageIndex + 1}`]}
          alt={product.name}
          className={`cards__img-item`}
        />
      </Link>
      <h4 className="cards__price-name">{product.name}</h4>
      <div className="cards__price">
        <h4 className="cards__price-new">{product.priceNew}</h4>
        <h4 className="cards__price-old">{product.priceOld}</h4>
      </div>
    </figure>
  );
}
