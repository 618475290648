import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const AddCardForm = ({ onAddCard }) => {
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [price, setPrice] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState('');
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [description, setDescription] = useState('');
  const [images, setImages] = useState([]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };

  const handleDiscountedPriceChange = (e) => {
    setDiscountedPrice(e.target.value);
  };

  const handleSizeChange = (e) => {
    const selectedSize = e.target.value;
    if (sizes.includes(selectedSize)) {
      setSizes(sizes.filter((size) => size !== selectedSize));
    } else {
      setSizes([...sizes, selectedSize]);
    }
  };

  const handleColorChange = (e) => {
    const selectedColor = e.target.value;
    if (colors.includes(selectedColor)) {
      setColors(colors.filter((color) => color !== selectedColor));
    } else {
      setColors([...colors, selectedColor]);
    }
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleImageChange = (e) => {
    const selectedImages = e.target.files;
    setImages(selectedImages);
};

  const handleSubmit = async (e) => {
  e.preventDefault();

  const formData = new FormData();
  formData.append('name', name);
  formData.append('category', category);
  formData.append('price', price);
  formData.append('discountedPrice', discountedPrice);
  formData.append('description', description);
  formData.append('sizes', sizes.join(','));
  formData.append('colors', colors.join(','));

  if (images) {
    for (let i = 0; i < images.length; i++) {
      formData.append('images', images[i]);
    }
  }

  const productId = uuidv4();

  onAddCard({ name, category, price, discountedPrice, description, images, sizes, colors, productId});


    // Сбросить состояние формы после отправки
    // setName('');
    // setCategory('');
    // setPrice('');
    // setDiscountedPrice('');
    // setSizes([]);
    // setColors([]);
    // setDescription('');
    // setImages([]);
};

  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      <label>
        Наименование:
        <input type="text" value={name} onChange={handleNameChange} />
      </label>

      <label>
        Категория:
        <select value={category} onChange={handleCategoryChange}>
          <option value="">Выберите категорию</option>
          <option value="Обувь">Обувь</option>
          <option value="Аксессуары">Аксессуары</option>
        </select>
      </label>

      <label>
        Стоимость:
        <input type="number" value={price} onChange={handlePriceChange} />
      </label>

      <label>
        Стоимость со скидкой:
        <input
          type="number"
          value={discountedPrice}
          onChange={handleDiscountedPriceChange}
        />
      </label>

      <fieldset>
        <legend>Размеры:</legend>
        <label>
          <input
            type="checkbox"
            value="35"
            checked={sizes.includes('35')}
            onChange={handleSizeChange}
          />
          35
        </label>
        <label>
          <input
            type="checkbox"
            value="36"
            checked={sizes.includes('36')}
            onChange={handleSizeChange}
          />
          36
        </label>
        <label>
          <input
            type="checkbox"
            value="37"
            checked={sizes.includes('37')}
            onChange={handleSizeChange}
          />
          37
        </label>
        <label>
          <input
            type="checkbox"
            value="38"
            checked={sizes.includes('38')}
            onChange={handleSizeChange}
          />
          38
        </label>
        <label>
          <input
            type="checkbox"
            value="39"
            checked={sizes.includes('39')}
            onChange={handleSizeChange}
          />
          39
        </label>
        <label>
          <input
            type="checkbox"
            value="40"
            checked={sizes.includes('40')}
            onChange={handleSizeChange}
          />
          40
        </label>
        <label>
          <input
            type="checkbox"
            value="41"
            checked={sizes.includes('41')}
            onChange={handleSizeChange}
          />
          41
        </label>
      </fieldset>

      <fieldset>
        <legend>Цвета:</legend>
        <label>
          <input
            type="checkbox"
            value="Черный"
            checked={colors.includes('Черный')}
            onChange={handleColorChange}
          />
          Черный
        </label>
        <label>
          <input
            type="checkbox"
            value="Белый"
            checked={colors.includes('Белый')}
            onChange={handleColorChange}
          />
          Белый
        </label>
        <label>
          <input
            type="checkbox"
            value="Кэмел"
            checked={colors.includes('Кэмел')}
            onChange={handleColorChange}
          />
          Кэмел
        </label>
        <label>
          <input
            type="checkbox"
            value="Светло-бежевый"
            checked={colors.includes('Светло-бежевый')}
            onChange={handleColorChange}
          />
          Светло-бежевый
        </label>
        <label>
          <input
            type="checkbox"
            value="Темно-бежевый"
            checked={colors.includes('Темно-бежевый')}
            onChange={handleColorChange}
          />
          Темно-бежевый
        </label>
        <label>
          <input
            type="checkbox"
            value="Слоновая кость"
            checked={colors.includes('Слоновая кость')}
            onChange={handleColorChange}
          />
          Слоновая кость
        </label>
        <label>
          <input
            type="checkbox"
            value="Темно-коричневый"
            checked={colors.includes('Темно-коричневый')}
            onChange={handleColorChange}
          />
          Темно-коричневый
        </label>
      </fieldset>

      <label>
        Описание товара:
        <textarea
          value={description}
          onChange={handleDescriptionChange}
          maxLength={250}
        />
      </label>

      <label>
        Добавление изображений:
        <input type="file" multiple onChange={handleImageChange} />
      </label>

      <button type="submit">Добавить товар</button>
    </form>
  );
};

export default AddCardForm;