import React, { useState } from 'react';
import {Link} from "react-router-dom";

export default function OrderReceived() {
	
	return (
		
		<section className="cart">
			<h1 className="cart__caption cart__caption-order">Заказ получен</h1>
			<section className="order-received">
				<img src={require('../../../images/GroupOk.png')} alt="Ок" className="order-received__img"/>
				<div className="order-received__info">
					<h3 className="order__title order-received__title">Заказ успешно оформлен</h3>
					<p className="order__product">Мы свяжемся с вами в ближайшее время!</p>
				</div>
				<Link to="/">
					<button className="cart__button-coupon">Перейти на главную</button>
				</Link>
			</section>
		</section>
	
	)
}