export const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3003' : 'https://api.boty.shoes';

function сheckResponseData(res) {
	if (res.ok) {
		return res.json();
	}
	return Promise.reject(`Ошибка: ${res.status}`);
}

export const register = (name, email, password) => {
	return fetch(`${BASE_URL}/signup`, {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ name, email, password })
	})
		.then(res => сheckResponseData(res));
};

export const authorize = (email, password) => {
	return fetch(`${BASE_URL}/signin`, {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ email, password })
	})
		.then(res => сheckResponseData(res));
};

export const getUserInfo = async (token) => {
	return fetch(`${BASE_URL}/users/me`, {
		method: 'GET',
		headers: {
			authorization: `Bearer ${localStorage.getItem('token')}`,
			'Content-Type': 'application/json',
		},
	})
		.then(res => сheckResponseData(res));
}

export const editProfile = (name, email) => {
	return fetch(`${BASE_URL}/users/me`, {
		method: "PATCH",
		headers: {
			authorization: `Bearer ${localStorage.getItem('token')}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			name,
			email,
		})
	})
		.then(res => сheckResponseData(res));
}

export const getCards = () => {
	return fetch(`${BASE_URL}/products`, {
		method: 'GET',
		headers: {
			authorization: `Bearer ${localStorage.getItem('token')}`,
			'Content-Type': 'application/json',
		},
	})
		.then(res => сheckResponseData(res))
}

export const postCards = (data) => {
  let sizes = [];
  if (typeof data.sizes === 'string') {
    sizes = data.sizes.split(',').map(String);
  } else if (Array.isArray(data.sizes)) {
    sizes = data.sizes.map(String);
  }

  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('category', data.category);
  formData.append('price', data.price);
  formData.append('discountedPrice', data.discountedPrice);
  formData.append('description', data.description);
  formData.append('sizes', JSON.stringify(sizes));
  formData.append('colors', JSON.stringify(data.colors));
  formData.append('productId', data.productId);

  if (data.images) {
    for (let i = 0; i < data.images.length; i++) {
      formData.append('images', data.images[i]);
			console.log('----- ', data.images[i]);
    }
  }

  return fetch(`${BASE_URL}/products`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: formData,
  })
    .then((res) => сheckResponseData(res));
};

export const deleteCard = (productId) => {
	return fetch(`${BASE_URL}/products/${productId._id}`, {
		method: "DELETE",
		headers: {
			authorization: `Bearer ${localStorage.getItem('token')}`,
			'Content-Type': 'application/json',
		},
	})
		.then(res => сheckResponseData(res));
}