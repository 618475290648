import React from 'react';
import {
	ABOUT_CONTENT_SUBTITLE_BE,
	ABOUT_CONTENT_SUBTITLE_WELCOME, ABOUT_CONTENT_TEXT_BE, ABOUT_CONTENT_TEXT_BE_TWO,
	ABOUT_CONTENT_TEXT_WELCOME,
	ABOUT_CONTENT_TEXT_WELCOME_TWO
} from "../../utils/constants";

export default function About() {
	return (

		<main className="content">
			{/*<h1 className="content__title">О нас</h1>*/}
			<section className="about">

				<article className="about__collection">
					<img src={require('../../images/about-1.jpg')} alt="Обувь" className="content__img"/>
						<div className="about__content">
							<h2 className="content__subtitle">{ABOUT_CONTENT_SUBTITLE_WELCOME}</h2>
							<p className="content__text">{ABOUT_CONTENT_TEXT_WELCOME}</p>
							<p className="content__text">{ABOUT_CONTENT_TEXT_WELCOME_TWO}</p>
						</div>
				</article>

				<article className="about__service">
					<div className="about__content">
						<h2 className="content__subtitle">{ABOUT_CONTENT_SUBTITLE_BE}</h2>
						<p className="content__text">{ABOUT_CONTENT_TEXT_BE}</p>
						<p className="content__text">{ABOUT_CONTENT_TEXT_BE_TWO}</p>
					</div>
					<img src={require('../../images/about-2.jpg')} alt="Обувь" className="content__img"/>
				</article>
			</section>
		</main>

	)
}