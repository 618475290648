import React from 'react';
import {Link} from "react-router-dom";
import Fullcart from "./fullcart/Fullcart";

export default function Cart() {
	return (
		
			<section className="cart">
				<h1 className="cart__caption">Корзина</h1>
				<section className="cart__header">
					<p className="cart__title">Товар</p>
					<div  className="cart__header-left">
						<p className="cart__title">Цена</p>
						<p className="cart__title">Количество</p>
						<p className="cart__title">Всего</p>
					</div>
				</section>
				<div  className="cart__line"></div>
				
				<Fullcart />
				<Fullcart />
				
				<div className="cart__coupon">
					<input
						className="cart__code"
						name="cart__price"
						type="text"
						// value={cardInput}
						placeholder="Введите купон"
						// onChange={handleInput}
						required
					/>
					<button className="cart__button-coupon">Применить купон</button>
				</div>
				
				<section className="cart__buy">
					<div className="cart__checkout">
						<div className="cart__synopsis">
							<h4 className="cart__checkout_text">Итого:</h4>
							<h4 className="cart__checkout_cost">6000 ₽</h4>
						</div>
						{/*<button className="product__contact cart__button">Оформить заказ</button>*/}
						<Link to="/order">
							<button className="product__contact cart__button">Оформить заказ</button>
						</Link>
					</div>
					
				</section>
			</section>
	
	)
}