import React from 'react';
import { Link } from 'react-router-dom';

export default function Menu({ isOpen, onClose }) {

	return (
		<nav className={`navigation ${isOpen ? "navigation_open" : ""}`} onClick={ onClose }>
			<button className="navigation__close" onClick={ onClose }></button>
			<Link to="/" className="navigation__menu">Магазин</Link>
			<Link to="/shoe" className="navigation__menu">Обувь</Link>
			<Link to="/accessorie" className="navigation__menu">Аксессуары</Link>
			{/*<Link to="/gift-cards" className="navigation__menu">Подарочные сертификаты</Link>*/}
			<Link to="/about" className="navigation__menu">О нас</Link>
			<Link to="/delivery" className="navigation__menu">Доставка</Link>
			<Link to="/contacts" className="navigation__menu">Контакты</Link>
			<Link to="/shoe-care" className="navigation__menu">Уход за обувью</Link>
			{/* <Link to="/cabinet" className="navigation__menu">Личный кабинет</Link> */}
			{/* <Link to="/cart" className="navigation__menu">Корзина</Link> */}

		</nav>
	)
}