import React from 'react';
import {Link} from "react-router-dom";

export default function Fullcart() {
	return (
		
			<section className="cart__product">
				<div className="cart__group">
					<button className="cart__delete"></button>
					<img src={require('../../../images/3HWFksZ11G4.jpg')} alt="Товар" className="cart__img"/>
				</div>
				<div className="cart__info">
					<div className="cart__description">
						<p className="cart__description-text">Сергио Обыкновенный</p>
						<p className="cart__description-text">Размер 41</p>
					</div>
					<div className="cart__header-left">
						<p className="cart__price">3000 ₽</p>
						<input
							className="cart__unit"
							name="cart__price"
							type="text"
							// value={cardInput}
							placeholder="1"
							// onChange={handleInput}
							required
						/>
						<p className="cart__price-total">3000 ₽</p>
					</div>
				</div>
				
			</section>
	
	)
}