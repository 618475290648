import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Menu from '../Menu/Menu';
import Preloader from '../Preloader/Preloader';

export default function Header({
	                               cardsArray,
	                               setCardsArray,
	                               cardInput,
	                               setCardInput,
	                               onSearch,
																 cards,
																 preloader,
																 setPreloader,
																 loggedIn
                               }) {

	const [ click, setClick ] = useState(false);

	function handleClick() {
		setClick(!click);
	}

	const handleClickSearch = (e) => {
		e.preventDefault();
		if (cardInput) {
			setPreloader(true);
			onSearch(cards);
		}
	}

	const handleInputChange = (e) => {
		setCardInput(e.target.value);
		console.log('++++++');
		// onSearch();
	};

	return (

		<header className="header">
			<Link to="/" className="header__logo"></Link>
			<button type="button" className="header__burger" onClick={handleClick}></button>
			<Menu isOpen={click} onClose={handleClick} />
			<nav className="header__menu">
				<div className="header__dropdown">
				{/* <Link to="/" className="header__content">Магазин</Link> */}
					<button className="header__item">Магазин</button>
					<div className="header__dropdown-content">
						<Link to="/shoe" className="header__content">Обувь</Link>
						<Link to="/accessorie" className="header__content">Аксессуары</Link>
						{/*<Link to="/shoes" className="header__content">Обувь</Link>*/}
						{/*<Link to="/accessories" className="header__content">Аксессуары</Link>*/}
						{/*<Link to="/gift-cards" className="header__content">Подарочные сертификаты</Link>*/}
					</div>
				</div>
				<Link to="/about" className="header__item">О нас</Link>
				<Link to="/delivery" className="header__item">Доставка</Link>
				<Link to="/contacts" className="header__item">Контакты</Link>
				<div className="header__dropdown">
					<button className="header__item">Клиентам</button>
					<div className="header__dropdown-content">
						{/*<Link to="/program" className="header__content">Программа лояльности</Link>*/}
						{/*<Link to="/cabinet" className="header__content">Личный кабинет</Link>*/}
						<Link to="/shoe-care" className="header__content">Уход за обувью</Link>
						{/*<Link to="/shoe-care" className="header__content">Информация</Link>*/}
					</div>
				</div>
			</nav>
			<nav className="header__button">
				{/* <div className="header__dropdown">
					<button type="button" className="header__search"></button>
					<form className="header__dropdown-form">
						<input
							className="header__search-film"
							name="search__film"
							type="text"
							value={cardInput}
							placeholder="Поиск"
							onChange={handleInputChange}
							required
						/>
						<button type="button" onClick={handleClickSearch} className="header__search-button" ></button>
					</form>
				</div> */}
				<a href="tel:+79956776572" className="header__phone"></a>
				{/*<Link to="/cabinet" className="header__profile"></Link>*/}
				{/* <Link to="/shoe-care" className="header__profile"></Link> */}

				{/* <div className="header__dropdown">
					<button className="header__profile"></button>
					<div className="header__dropdown-content">
						{!loggedIn ? (<Link to="/signup" className="header__content">Регистрация</Link>) : (<></>)}
						<Link to="/signin" className="header__content">{!loggedIn ? "Войти" : "Профиль"}</Link>
					</div>
				</div> */}

				{/* <Link to="/cart" className="header__cart full-cart"></Link> */}
				{/*<Link to="/delivery" className="header__cart"></Link>*/}
			</nav>
		</header>

	)
}