export const products = [
  {
    id: "1",
    name: "Казаки",
    category: "shoes",
    priceNew: "6690 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Кэмел", class: "product__color_two" }],
    description:
      "Казаки на скошенном каблуке в цвете кэмел подойдут для тех, кто хочет добавить в образ свежести и легкости. Универсальная модель без акцентных деталей и яркой фурнитуры способна поддержать даже деловой образ. Для создания пары с зауженной носочной частью выбрали бархатистую замшу, которая подчеркивает глубину оттенка. Модель с широким голенищем и эластичными петлями легко надевать без застежки. Смело комбинируйте пару с любыми вещами из базового или нарядного гардероба. Модный стилистический прием, с которым вы точно окажетесь в центре внимания – заправьте любимые джинсы в казаки, как это делает Хейли Бибер и когда-то делала принцесса Диана.",
    img1: "/shoes/001/001-Cossacks-01.jpg",
    img2: "/shoes/001/001-Cossacks-02.jpg",
    img3: "/shoes/001/001-Cossacks-03.jpg",
    img4: "/shoes/001/001-Cossacks-04.jpg",
    img5: "/shoes/001/001-Cossacks-05.jpg",
    img6: "/shoes/001/001-Cossacks-06.jpg",
  },
  {
    id: "2",
    name: "Лоферы",
    category: "shoes",
    priceNew: "4590 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Темно-бежевый", class: "product__color_four" }],
    description:
      "Классические лоферы, выполненные из гладкой натурального замши темно-бежевого цвета, — базовая модель, которая просто обязана быть в гардеробе каждой модницы. Носочная часть имеет продолговатую форму. Благодаря такой форме стопа будет чувствовать себя очень комфортно. Модель имеет гибкую подошву — просто идеально для ежедневной носки. Лоферы подойдут тем, кто не любит лишнего: пара украшена аккуратной отстрочкой и лаконичными аксессуарами.",
    img1: "/shoes/002/002-Loafers-01.jpg",
    img2: "/shoes/002/002-Loafers-02.jpg",
    img3: "/shoes/002/002-Loafers-03.jpg",
    img4: "/shoes/002/002-Loafers-04.jpg",
    img5: "/shoes/002/002-Loafers-05.jpg",
    img6: "/shoes/002/002-Loafers-06.jpg",
  },
  {
    id: "3",
    name: "Лоферы",
    category: "shoes",
    priceNew: "5490 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [
      { name: "Темно-бежевый", class: "product__color_four" },
      { name: "Слоновая кость", class: "product__color_five" },
    ],
    description:
      "Универсальный цвет, выверенный силуэт и лаконичный дизайн – лоферы из новой коллекции выглядят предельно сдержанно и аскетично. Модель выполнена из натуральной замши цвета слоновой кости. За комфорт отвечают плоская гибкая подошва и стелька из натуральной кожи. Такая модель станет идеальным дополнением для деловых и повседневных образов.",
    img1: "/shoes/003/003-Loafers-01.jpg",
    img2: "/shoes/003/003-Loafers-02.jpg",
    img3: "/shoes/003/003-Loafers-03.jpg",
    img4: "/shoes/003/003-Loafers-04.jpg",
    img5: "/shoes/003/003-Loafers-05.jpg",
    img6: "/shoes/003/003-Loafers-06.jpg",
  },
  {
    id: "4",
    name: "Казаки",
    category: "shoes",
    priceNew: "4290 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Черные казаки на скошенном каблуке смогут поддержать даже офисный образ с жакетом и прямыми брюками. За универсальность модели отвечает базовый цвет и лаконичный дизайн без лишних деталей. Для создания пары с зауженной носочной частью выбрали гладкую эко-кожу. Модель с широким голенищем и эластичными петлями легко надевать без застежки. Смело комбинируйте пару с любыми вещами из базового или нарядного гардероба. Модный стилистический прием, с которым вы точно окажетесь в центре внимания – заправьте голубые джинсы в казаки, как это делает Хейли Бибер и когда-то делала принцесса Диана.",
    img1: "/shoes/004/004-Cossacks-01.jpg",
    img2: "/shoes/004/004-Cossacks-02.jpg",
    img3: "/shoes/004/004-Cossacks-03.jpg",
    img4: "/shoes/004/004-Cossacks-04.jpg",
    img5: "/shoes/004/004-Cossacks-05.jpg",
    img6: "/shoes/004/004-Cossacks-06.jpg",
  },
  {
    id: "5",
    name: "Челси",
    category: "shoes",
    priceNew: "2590 ₽",
    priceOld: "4590 ₽",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40", "41"],
    color: [{ name: "Светло-бежевый", class: "product__color_three" }],
    description:
      "Зимние челси в классическом дизайне. Модель выполнена из бархатистого текстильного нубука. По бокам - традиционные эластичные вставки в тон изделия. Внутри натуральная шерсть. Замки с внутренней стороны помогают легко снимать и надевать пару. Завершает модель светлая подошва. Лаконичные челси гармонично дополнят повседневные образы.",
    img1: "/shoes/005/005-Chelsea-01.jpg",
    img2: "/shoes/005/005-Chelsea-02.jpg",
    img3: "/shoes/005/005-Chelsea-03.jpg",
    img4: "/shoes/005/005-Chelsea-04.jpg",
    img5: "/shoes/005/005-Chelsea-01.jpg",
    img6: "/shoes/005/005-Chelsea-02.jpg",
  },
  {
    id: "6",
    name: "Cапоги-трубы",
    category: "shoes",
    priceNew: "3590 ₽",
    priceOld: "6590 ₽",
    country: "Китай",
    sizes: ["35", "36", "37", "38"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Несмотря на универсальный цвет и лаконичный дизайн, зимние сапоги-трубы привлекают внимание. Все дело в подошве, которая добавляет паре эффектности. Модель выполнена из натуральной гладкой кожи. Внутри до щиколотки - натуральная шерсть, по длине голени - комфортный текстильный подклад из байки. Свободная посадка позволяет заправлять в сапоги брюки или джинсы. Такая пара также будет хорошо сочетаться с летящими платьями, добавляя в образ нотку брутальности.",
    img1: "/shoes/006/006-TubeBoots-01.jpg",
    img2: "/shoes/006/006-TubeBoots-02.jpg",
    img3: "/shoes/006/006-TubeBoots-03.jpg",
    img4: "/shoes/006/006-TubeBoots-04.jpg",
    img5: "/shoes/006/006-TubeBoots-05.jpg",
    img6: "/shoes/006/006-TubeBoots-06.jpg",
  },
  {
    id: "7",
    name: "Ботильоны",
    category: "shoes",
    priceNew: "4590 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Классическая модель ботильонов-стрейч в черном цвете приобретает новое прочтение и приковывает к себе взгляды за счет открытой носочной части. Пару на шпильке с квадратной носочной частью выполнили из прочного эластичного текстиля и дополнили замком с внутренней стороны. Благодаря этому вы можете легко и элегантно снимать и надевать ботильоны. Такую обувь не стоит прятать: комбинируйте ее с джинсами-скини, элегантными платьями, мини-шортами и брюками-карго.",
    img1: "/shoes/007/007-StretchBoots-01.jpg",
    img2: "/shoes/007/007-StretchBoots-02.jpg",
    img3: "/shoes/007/007-StretchBoots-03.jpg",
    img4: "/shoes/007/007-StretchBoots-04.jpg",
    img5: "/shoes/007/007-StretchBoots-05.jpg",
    img6: "/shoes/007/007-StretchBoots-06.jpg",
  },
  {
    id: "8",
    name: "Казаки",
    category: "shoes",
    priceNew: "8490 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Серый", class: "product__color_eleven" }],
    description:
      "Серые казаки с широким голенищем и вышивкой, которая подчеркивает аутентичную форму сапог. Для модели была выбрана нежнейшая замша на подкладке из светлой натуральной кожи. Смело комбинируйте пару с любыми вещами из базового или нарядного гардероба. Модный стилистический прием, с которым вы точно окажетесь в центре внимания – заправьте любимые джинсы в казаки, как это делает Хейли Бибер и когда-то делала принцесса Диана.",
    img1: "/shoes/008/008-Cossacks-01.jpg",
    img2: "/shoes/008/008-Cossacks-02.jpg",
    img3: "/shoes/008/008-Cossacks-03.jpg",
    img4: "/shoes/008/008-Cossacks-04.jpg",
    img5: "/shoes/008/008-Cossacks-05.jpg",
    img6: "/shoes/008/008-Cossacks-06.jpg",
  },
  {
    id: "9",
    name: "Кроссовки",
    category: "shoes",
    priceNew: "3290 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40", "41"],
    color: [{ name: "Белый", class: "product__color_seven" }],
    description:
      "Мода на симбиоз эпатажа и ностальгии по эпохе 90-х. Тренд на объемные кроссовки был воспринят с восторгом модным обществом, поэтому он не теряет своей актуальности уже несколько сезонов подряд. В новой коллекции дизайнеры создали летний вариант кроссовок с объемной подошвой. Пару выполнили из белой матовой кожи. Отдельного внимания заслуживают сетчатые вставки, которые обеспечивают циркуляцию воздуха и позволяют носить пару в жаркую погоду и делает обувь невероятно легкой и мобильной. В комплект входят носочки.",
    img1: "/shoes/009/009-Sneakers-01.jpg",
    img2: "/shoes/009/009-Sneakers-02.jpg",
    img3: "/shoes/009/009-Sneakers-03.jpg",
    img4: "/shoes/009/009-Sneakers-01.jpg",
    img5: "/shoes/009/009-Sneakers-02.jpg",
    img6: "/shoes/009/009-Sneakers-03.jpg",
  },
  {
    id: "10",
    name: "Cапоги-трубы",
    category: "shoes",
    priceNew: "1990 ₽",
    priceOld: "4290 ₽",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Бежевый", class: "product__color_eight" }],
    description:
      "Без универсальных сапог не обходится ни один зимний гардероб, а сапоги-трубы занимают особенное место благодаря удобству и универсальности. Модель выполнена из эко-кожи актуального бежевого оттенка. Пара в классическом стиле, с аккуратными швами и отсутствием фурнитуры отражает тренд сезона на минимализм и впишется в любой гардероб. Дизайнеры утеплили сапоги натуральной шерстью до щиколотки и мягкой подкладкой из байки, чтобы Вы могли создавать стильные и женственные образы, даже зимой.",
    img1: "/shoes/010/010-TubeBoots-01.jpg",
    img2: "/shoes/010/010-TubeBoots-02.jpg",
    img3: "/shoes/010/010-TubeBoots-03.jpg",
    img4: "/shoes/010/010-TubeBoots-04.jpg",
    img5: "/shoes/010/010-TubeBoots-01.jpg",
    img6: "/shoes/010/010-TubeBoots-02.jpg",
  },
  {
    id: "11",
    name: "Ботинки",
    category: "shoes",
    priceNew: "3990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Ботинки с пряжками стали настоящим хитом последнего сезона. Сочетай их с юбкой или леггами, брюки-карго или джинсы - в любом случае образ будет офигенным🔥 а ботинки станут его изюминкой.",
    img1: "/shoes/011/011-Boots-01.jpg",
    img2: "/shoes/011/011-Boots-02.jpg",
    img3: "/shoes/011/011-Boots-03.jpg",
    img4: "/shoes/011/011-Boots-04.jpg",
    img5: "/shoes/011/011-Boots-01.jpg",
    img6: "/shoes/011/011-Boots-02.jpg",
  },
  {
    id: "12",
    name: "Ботинки",
    category: "shoes",
    priceNew: "",
    priceOld: "3490 ₽",
    country: "Китай",
    sizes: [],
    color: [{ name: "Бежевый", class: "product__color_eight" }],
    description:
      "Ботинки с мягким верхом подойдут как для теплых весенних дней, так и летних прохладных  вечеров. Модель выполнена из бархатистого текстильного велюра в бежевом цвете. Подкладка сделана из мягкого текстиля. Акцентная деталь - массивная подошва из облегченной резины. Ботинки будут хорошо сочетаться с брюками-карго, шортами и любыми спортивными образами.",
    img1: "/shoes/012/012-Boots-01.jpg",
    img2: "/shoes/012/012-Boots-02.jpg",
    img3: "/shoes/012/012-Boots-03.jpg",
    img4: "/shoes/012/012-Boots-04.jpg",
    img5: "/shoes/012/012-Boots-05.jpg",
    img6: "/shoes/012/012-Boots-06.jpg",
  },
  {
    id: "13",
    name: "Шопер-mini с косметичкой",
    category: "accessories",
    priceNew: "2590 ₽",
    priceOld: "",
    country: "Китай",
    size: "",
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Шопер-mini с косметичкой. Внутрь поместится все самое необходимое. Несмотря на небольшой размер шоппер выглядит статусно, поэтому отлично подойдет для важных встреч и офисных образов. Изделие выполнено из высококачественной искусственной мягкой кожи, которая готова прослужить вам ни один сезон. В комплект также входит длинный кожаный регулируемый ремень.",
    img1: "/accessories/001/001-Chopper-01.jpg",
    img2: "/accessories/001/001-Chopper-02.jpg",
    img3: "/accessories/001/001-Chopper-03.jpg",
    img4: "/accessories/001/001-Chopper-04.jpg",
    img5: "/accessories/001/001-Chopper-05.jpg",
    img6: "/accessories/001/001-Chopper-01.jpg",
  },
  {
    id: "14",
    name: "Сумка-mini",
    category: "accessories",
    priceNew: "2590 ₽",
    priceOld: "",
    country: "Китай",
    sizes: "",
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Компактная сумка из гладкой эко-кожи – модель для повседневных базовых образов. В комплект входит 2 съемных ремня – с цепочкой, который удобно носить на плече и кожаный ремень кроссбоди. Внутри аксессуара есть дополнительные отделения для ключей, карточек и телефона. Перекидной клапан завершается магнитным замком золотого цвета. Это лаконичная сумка на все случаи жизни, которая подойдет под разный образ и стиль.",
    img1: "/accessories/002/002-Bag-01.jpg",
    img2: "/accessories/002/002-Bag-02.jpg",
    img3: "/accessories/002/002-Bag-03.jpg",
    img4: "/accessories/002/002-Bag-04.jpg",
    img5: "/accessories/002/002-Bag-05.jpg",
    img6: "/accessories/002/002-Bag-01.jpg",
  },
  {
    id: "15",
    name: "Сумка-полумесяц",
    category: "accessories",
    priceNew: "4590 ₽",
    priceOld: "",
    country: "Китай",
    sizes: "",
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Сумка-полумесяц или сумка хобо черного цвета — трендовый аксессуар, который не теряет своей актуальности уже несколько сезонов подряд. Необычайно вместительная сумка гармонично сочетается с нарядами различной стилистики. Дизайнеры для создания модели использовали натуральную кожу с крупным зернением, которая очень прочная и долговечная в носке. У модели жесткое дно, что позволяет держать форму аксессуара при ношении. В комплект входит съемный кожаный ремень на карабинах.",
    img1: "/accessories/003/003-Bag-01.jpg",
    img2: "/accessories/003/003-Bag-02.jpg",
    img3: "/accessories/003/003-Bag-03.jpg",
    img4: "/accessories/003/003-Bag-04.jpg",
    img5: "/accessories/003/003-Bag-01.jpg",
    img6: "/accessories/003/003-Bag-02.jpg",
  },
  {
    id: "16",
    name: "Римские сандалии",
    category: "shoes",
    priceNew: "3490 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40", "41"],
    color: [{ name: "Белый", class: "product__color_seven" }],
    description:
      "Римские сандалии на липучках из эко-кожи в белом цвете — ода комфорту и минимализму. Мягкая стелька анатомической формы сделает любой летний день в городской суете более комфортным и расслабленным. Лаконичное исполнение и чистый светлый оттенок открывают простор для повседневных сочетаний. Выбирайте не только базовый casual, но и более смелый спортшик для особого настроения. А сандалии позаботятся об уверенной походке и долгих приятных прогулках.",
    img1: "/shoes/013/013-Boots-01.jpg",
    img2: "/shoes/013/013-Boots-02.jpg",
    img3: "/shoes/013/013-Boots-03.jpg",
    img4: "/shoes/013/013-Boots-04.jpg",
    img5: "/shoes/013/013-Boots-05.jpg",
    img6: "/shoes/013/013-Boots-06.jpg",
  },
  {
    id: "17",
    name: "Сандалии fisherman",
    category: "shoes",
    priceNew: "3490 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39"],
    color: [{ name: "Белый", class: "product__color_seven" }],
    description:
      "Горячий тренд последних летних сезонов - Сандалии fisherman. Они полюбились модницам за их комфорт, универсальность и стильный дизайн. В летней коллекции дизайнеры представили новую версию полюбившейся пары, сделав акцент на подошве. Модель из мягкой эко-кожи в белом цвете и с характерной конструкцией из широких ремней застегивается на пряжку. Сандалии fisherman — это одна из тех пар, которую можно носить с гольфами и носками и при этом выглядеть стильно и актуально. Комбинируйте пару с любимой повседневной одеждой и создавайте модные расслабленные образы.",
    img1: "/shoes/014/014-Boots-01.jpg",
    img2: "/shoes/014/014-Boots-02.jpg",
    img3: "/shoes/014/014-Boots-03.jpg",
    img4: "/shoes/014/014-Boots-04.jpg",
    img5: "/shoes/014/014-Boots-05.jpg",
    img6: "/shoes/014/014-Boots-06.jpg",
  },
  {
    id: "18",
    name: "Босоножки на шнуровке",
    category: "shoes",
    priceNew: "3590 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Босоножки на шнуровке — один из ключевых трендов летнего сезона. Звезды стритстайла обожают сочетать их с повседневным гардеробом, что вполне оправданно, ведь они удобные, не натирают ногу и смотрятся эффектно. Пара из гладкой эко-кожи с трендовой квадратной носочной частью завершается ассиметричным  каблуком. Мягкая кожаная шнуровка нежно облегает щиколотку и повышает градус женственности всего образа.",
    img1: "/shoes/015/015-Boots-01.jpg",
    img2: "/shoes/015/015-Boots-02.jpg",
    img3: "/shoes/015/015-Boots-03.jpg",
    img4: "/shoes/015/015-Boots-04.jpg",
    img5: "/shoes/015/015-Boots-05.jpg",
    img6: "/shoes/015/015-Boots-06.jpg",
  },
  {
    id: "19",
    name: "Босоножки",
    category: "shoes",
    priceNew: "3990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Бежевый", class: "product__color_eight" }],
    description:
      "Босоножки с квадратной носочной частью. Конструкция из мягких узких ремней подчеркивает женственный силуэт стопы. Модель из мягкой эко- кожи выполнена в нейтральном бежевом оттенке, который легко комбинируется с любыми цветовыми решениями. Невысокий скульптурный каблук в сочетании с удобной колодкой и мягкой стелькой отвечают за комфорт во время движения. Комбинировать стильную пару рекомендуется с платьями или с укороченными джинсами, или как многие инфлюенсеры, с расслабленными брючными костюмами.",
    img2: "/shoes/016/016-Boots-02.jpg",
    img1: "/shoes/016/016-Boots-01.jpg",
    img3: "/shoes/016/016-Boots-03.jpg",
    img5: "/shoes/016/016-Boots-05.jpg",
    img4: "/shoes/016/016-Boots-04.jpg",
    img6: "/shoes/016/016-Boots-06.jpg",
  },
  {
    id: "20",
    name: "Лоферы",
    category: "shoes",
    priceNew: "5490 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [
      { name: "Темно-коричневый", class: "product__color_six" },
      { name: "Бежевый", class: "product__color_eight" },
      { name: "Cветло-серый", class: "product__color_ten" },
    ],
    description:
      "Лаконичные лоферы — универсальная пара, в которой органично сочетаются между собой удобство и стильный дизайн. При создании этой модели дизайнеры сделали ставку на цвет и фактуру материала. Модель выполнили из натуральной бархатистой замши. Такая пара будет одинаково уместна как в офисе, так и на празднике или официальном мероприятии.",
    img2: "/shoes/017/017-Boots-02.jpg",
    img1: "/shoes/017/017-Boots-01.jpg",
    img3: "/shoes/017/017-Boots-03.jpg",
    img5: "/shoes/017/017-Boots-05.jpg",
    img4: "/shoes/017/017-Boots-04.jpg",
    img6: "/shoes/017/017-Boots-06.jpg",
  },
  {
    id: "21",
    name: "Сумка-полумесяц",
    category: "accessories",
    priceNew: "2790 ₽",
    priceOld: "",
    country: "Китай",
    sizes: "",
    color: [
      { name: "Белый", class: "product__color_seven" },
      { name: "Черный", class: "product__color_one" },
      { name: "Бежевый", class: "product__color_eight" },
      { name: "Молочный", class: "product__color_nine" },
    ],
    description:
      "Сумка-полумесяц или сумка хобо — трендовый аксессуар, который не теряет своей актуальности уже несколько сезонов подряд. Необычайно вместительная сумка гармонично сочетается с образами в различных стилях. Дизайнеры для создания модели использовали эко-кожу с крупным зернением. В комплект входит кожаный ремень на карабинах.",
    img1: "/accessories/004/004-Bag-01.jpg",
    img2: "/accessories/004/004-Bag-02.jpg",
    img3: "/accessories/004/004-Bag-03.jpg",
    img4: "/accessories/004/004-Bag-04.jpg",
    img5: "/accessories/004/004-Bag-05.jpg",
    img6: "/accessories/004/004-Bag-06.jpg",
  },
  {
    id: "22",
    name: "Сандалии",
    category: "shoes",
    priceNew: "4290 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Сандалии с тонкими ремешками — самая комфортная и универсальная пара сезона. Модель выполнена из эко-кожи черного цвета. Мягкие ремни фиксируют стопу и щиколотку. Регулируемый ремень с пряжкой можно кастомизировать подвесками для создания дополнительного яркого акцента. Идеальны для дерзкого гранжа в союзе с рваными шортами или плиссированной мини юбкой в клетку.",
    img2: "/shoes/018/018-Boots-02.jpg",
    img1: "/shoes/018/018-Boots-01.jpg",
    img3: "/shoes/018/018-Boots-03.jpg",
    img5: "/shoes/018/018-Boots-05.jpg",
    img4: "/shoes/018/018-Boots-04.jpg",
    img6: "/shoes/018/018-Boots-06.jpg",
  },
  // {
  // 	id: '23',
  // 	name: 'Лоферы',
  // 	category: 'shoes',
  // 	priceNew: '4590 ₽',
  // 	priceOld: '',
  // 	country: 'Китай',
  // 	sizes: ['36', '37', '38', '39', '40'],
  // 	color: [
  // 		{ name: 'Темно-бежевый', class: 'product__color_four' },
  // 	],
  // 	description: 'Лаконичные лоферы — универсальная пара, в которой органично сочетаются между собой удобство и стильный дизайн. При создании этой модели дизайнеры сделали ставку на цвет и фактуру материала. Модель выполнили из натуральной гладкой замши. Такая пара будет одинаково уместна как в офисе, так и на празднике или официальном мероприятии.',
  // 	img2: '/shoes/019/019-Boots-02.jpg',
  // 	img1: '/shoes/019/019-Boots-01.jpg',
  // 	img3: '/shoes/019/019-Boots-03.jpg',
  // 	img5: '/shoes/019/019-Boots-05.jpg',
  // 	img4: '/shoes/019/019-Boots-04.jpg',
  // 	img6: '/shoes/019/019-Boots-06.jpg'
  // },
  {
    id: "24",
    name: "Кроссовки",
    category: "shoes",
    priceNew: "2990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Белый", class: "product__color_seven" }],
    description:
      "Летняя модель кроссовок станет отличным выбором для тех, кто ведет активный образ жизни. Пару выполнили из эко-кожи белого цвета. Сегодня стилисты ломают стереотипы и смело сочетают объемные кроссовки не только со спортивным стилем, но и с изящной женственной одеждой, создавая неожиданные яркие образы.",
    img2: "/shoes/020/020-Boots-02.jpg",
    img1: "/shoes/020/020-Boots-01.jpg",
    img3: "/shoes/020/020-Boots-03.jpg",
    img5: "/shoes/020/020-Boots-05.jpg",
    img4: "/shoes/020/020-Boots-04.jpg",
    img6: "/shoes/020/020-Boots-06.jpg",
  },
  {
    id: "25",
    name: "Босоножки",
    category: "shoes",
    priceNew: "3990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Белый", class: "product__color_seven" }],
    description:
      "Босоножки с квадратной носочной частью. Конструкция из мягких узких ремней подчеркивает женственный силуэт стопы. Модель из мягкой эко- кожи легко комбинируется с любыми цветовыми решениями. Невысокий скульптурный каблук в сочетании с удобной колодкой и мягкой стелькой отвечают за комфорт во время движения. Комбинировать стильную пару рекомендуется с платьями или с укороченными джинсами, или как многие инфлюенсеры, с расслабленными брючными костюмами.",
    img2: "/shoes/021/021-Boots-02.jpg",
    img1: "/shoes/021/021-Boots-01.jpg",
    img3: "/shoes/021/021-Boots-03.jpg",
    img5: "/shoes/021/021-Boots-05.jpg",
    img4: "/shoes/021/021-Boots-04.jpg",
    img6: "/shoes/021/021-Boots-06.jpg",
  },
  {
    id: "26",
    name: "Сандалии",
    category: "shoes",
    priceNew: "3490 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39"],
    color: [{ name: "Белый", class: "product__color_seven" }],
    description:
      "Яркий тренд летнего сезона — сандалии в стиле fisherman. Пара выполнена из мягкой эко-кожи белого цвета. Модель отличает оригинальная сложная конструкция из широких ремней, которые надежно фиксируют стопу и акцентируют внимание на изящном силуэте ваших ног. Легкая подошва с мягкой стелькой — важная деталь, которая также отвечает за комфорт во время ходьбы. Такая пара подойдет тем, кто привык выглядеть модно, даже в насыщенные и динамичные дни.",
    img2: "/shoes/022/022-Boots-02.jpg",
    img1: "/shoes/022/022-Boots-01.jpg",
    img3: "/shoes/022/022-Boots-03.jpg",
    img5: "/shoes/022/022-Boots-05.jpg",
    img4: "/shoes/022/022-Boots-04.jpg",
    img6: "/shoes/022/022-Boots-06.jpg",
  },
  {
    id: "27",
    name: "Босоножки",
    category: "shoes",
    priceNew: "3990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Белый", class: "product__color_seven" }],
    description:
      "Акцентные босоножки в цвете Шампань из нежного атласа автоматически выводит образ на новый уровень. Такие Босоножки — самый простой способ привлечь внимание к образу и быть центром притяжения восторженных взглядов. Трендовый дизайн пары с квадратной носочной частью делает акцент на женственных изгибах ноги. Пару выполнили в цвете Шампань, который является таким же нейтральным, как все пастельные оттенки, но выглядит свежо и актуально.",
    img2: "/shoes/023/023-Boots-02.jpg",
    img1: "/shoes/023/023-Boots-01.jpg",
    img3: "/shoes/023/023-Boots-03.jpg",
    img5: "/shoes/023/023-Boots-05.jpg",
    img4: "/shoes/023/023-Boots-04.jpg",
    img6: "/shoes/023/023-Boots-06.jpg",
  },
  {
    id: "28",
    name: "Кеды",
    category: "shoes",
    priceNew: "2990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["35", "36", "37", "38", "39", "40"],
    color: [{ name: "Белый", class: "product__color_seven" }],
    description:
      "Лаконичные кеды на шнуровке уже давно получили статус универсальной базовой обуви. Особенность модели — серые вставки из эко-нубука. Такая модель будет актуальна как с спортивными образами , так и с более женственными.",
    img2: "/shoes/024/024-Boots-02.jpg",
    img1: "/shoes/024/024-Boots-01.jpg",
    img3: "/shoes/024/024-Boots-03.jpg",
    img5: "/shoes/024/024-Boots-05.jpg",
    img4: "/shoes/024/024-Boots-04.jpg",
    img6: "/shoes/024/024-Boots-06.jpg",
  },
  {
    id: "29",
    name: "Сандалии",
    category: "shoes",
    priceNew: "3490 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Белый", class: "product__color_seven" }],
    description:
      "В устойчивых сандалиях твой жизненный шаг точно станет увереннее. Идеальны для дерзкого гранжа в союзе с рваными шортами или плиссированной мини юбкой в клетку. Ремешок на щиколотке, тракторная подошва будут эффектно перекликаться с деталями в образе в тон.",
    img2: "/shoes/025/025-Boots-02.jpg",
    img1: "/shoes/025/025-Boots-01.jpg",
    img3: "/shoes/025/025-Boots-03.jpg",
    img5: "/shoes/025/025-Boots-05.jpg",
    img4: "/shoes/025/025-Boots-04.jpg",
    img6: "/shoes/025/025-Boots-06.jpg",
  },
  {
    id: "30",
    name: "Короткие угги",
    category: "shoes",
    priceNew: "5490 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40", "41"],
    color: [
      { name: "Черный", class: "product__color_one" },
      { name: "Бежевый", class: "product__color_eight" },
    ],
    description:
      "Уютные короткие угги из натуральной замши и шерсти – стиль и тепло в каждой детали! Выберите между бежевым и черным для подчеркивания вашего стиля. Прочная натуральная замша верха и мягкая шерсть внутри обеспечивают комфорт. Термопластичная резиновая подошва гарантирует надежное сцепление. Идеальный выбор для модного уюта в любое время года!",
    img2: "/shoes/026/026-Boots-01.jpg",
    img1: "/shoes/026/026-Boots-02.jpg",
    img3: "/shoes/026/026-Boots-03.jpg",
    img5: "/shoes/026/026-Boots-04.jpg",
    img4: "/shoes/026/026-Boots-05.jpg",
    img6: "/shoes/026/026-Boots-06.jpg",
  },
  {
    id: "31",
    name: "Казаки",
    category: "shoes",
    priceNew: "8490 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40", "41"],
    color: [
      { name: "Серый", class: "product__color_eleven" },
      { name: "Темно-бежевый", class: "product__color_four" },
    ],
    description:
      "Погружайтесь в стиль с высокими казаками из натуральной замши! Оттенки серого и темно-бежевого подчеркнут вашу элегантность. Верх из прочной натуральной замши, внутри - мягкая натуральная кожа для максимального комфорта. Идеальное дополнение к тренчу, платью или жакету, создавая идеальный образ для любого повода.",
    img2: "/shoes/027/027-Boots-01.jpg",
    img1: "/shoes/027/027-Boots-02.jpg",
    img3: "/shoes/027/027-Boots-03.jpg",
    img5: "/shoes/027/027-Boots-04.jpg",
    img4: "/shoes/027/027-Boots-05.jpg",
    img6: "/shoes/027/027-Boots-06.jpg",
  },
  {
    id: "32",
    name: "Сумочка mini",
    category: "accessories",
    priceNew: "3990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: "",
    color: [
      { name: "Черный", class: "product__color_one" },
      { name: "Белый", class: "product__color_seven" },
      { name: "Темно-бежевый", class: "product__color_four" },
    ],
    description:
      "Мини-сумочка из плотной эко-кожи – идеальное сочетание стиля и практичности! Выберите свой оттенок: черный, соленая карамель или белый, с золотой или серебряной фурнитурой. Материал верха - высококачественная эко-кожа. В комплекте два ремешка: кожаный и тканевый. Компактные размеры 22 см x 14 см подчеркнут вашу элегантность, делая эту сумочку идеальным аксессуаром для любого образа.",
    img2: "/accessories/005/005-Bag-01.jpg",
    img1: "/accessories/005/005-Bag-02.jpg",
    img3: "/accessories/005/005-Bag-03.jpg",
    img5: "/accessories/005/005-Bag-04.jpg",
    img4: "/accessories/005/005-Bag-05.jpg",
    img6: "/accessories/005/005-Bag-06.jpg",
  },
  {
    id: "33",
    name: "Босоножки",
    category: "shoes",
    priceNew: "3990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Элегантные базовые босоножки из атласа – идеальный выбор для стильных визуальных акцентов! Каблук высотой 9 см добавит изысканности вашему образу. Материал верха – качественный атлас, придающий изысканность и легкость. Доступны в различных оттенках. Шикарный выбор для завершения любого наряда, придавая вам уверенность и элегантность.",
    img2: "/shoes/028/028-Boots-01.jpg",
    img1: "/shoes/028/028-Boots-02.jpg",
    img3: "/shoes/028/028-Boots-03.jpg",
    img5: "/shoes/028/028-Boots-04.jpg",
    img4: "/shoes/028/028-Boots-05.jpg",
    img6: "/shoes/028/028-Boots-06.jpg",
  },
  {
    id: "34",
    name: "Сапоги трубы",
    category: "shoes",
    priceNew: "5990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Элегантные сапоги трубы на удобном каблуке - идеальное сочетание стиля и комфорта! Высота каблука 8 см придает изысканность, а материал верха из эко-кожи подчеркивает элегантность. Эти стильные сапоги станут отличным дополнением к вашему образу, обеспечивая уверенность и комфорт в каждом шаге.",
    img2: "/shoes/029/029-Boots-01.jpg",
    img1: "/shoes/029/029-Boots-02.jpg",
    img3: "/shoes/029/029-Boots-03.jpg",
    img5: "/shoes/029/029-Boots-04.jpg",
    img4: "/shoes/029/029-Boots-05.jpg",
    img6: "/shoes/029/029-Boots-06.jpg",
  },
  {
    id: "35",
    name: "Ботинки",
    category: "shoes",
    priceNew: "3990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "38", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Ботинки в стиле 'The Row' с элегантным замком - совершенное сочетание стиля и функциональности! Черный цвет подчеркивает изысканность. Верх из прочной эко-кожи, внутри - комфортный текстильный подклад. Термоустойчивая резиновая подошва обеспечивает надежное сцепление. Эти ботинки – не просто обувь, а стильное выражение вашего утонченного вкуса и комфорта в каждом движении.",
    img2: "/shoes/030/030-Boots-01.jpg",
    img1: "/shoes/030/030-Boots-02.jpg",
    img3: "/shoes/030/030-Boots-03.jpg",
    img5: "/shoes/030/030-Boots-04.jpg",
    img4: "/shoes/030/030-Boots-05.jpg",
    img6: "/shoes/030/030-Boots-06.jpg",
  },
  {
    id: "36",
    name: "Туфли",
    category: "shoes",
    priceNew: "3490 ₽",
    priceOld: "3990 ₽",
    country: "Китай",
    sizes: ["36", "37", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Подчеркните свой стиль с туфлями, где детали играют главную роль! Эффектная акцентная цепочка придает изысканность. Черный цвет создает элегантный контраст. Верх из текстиля атлас, внутри - нежная кожа. Удобный каблук высотой 7 см добавит изысканности. Эти туфли - идеальное сочетание стиля и комфорта для выделения вашего неповторимого образа.",
    img2: "/shoes/031/031-Boots-01.jpg",
    img1: "/shoes/031/031-Boots-02.jpg",
    img3: "/shoes/031/031-Boots-03.jpg",
    img5: "/shoes/031/031-Boots-04.jpg",
    img4: "/shoes/031/031-Boots-05.jpg",
    img6: "/shoes/031/031-Boots-06.jpg",
  },
  {
    id: "37",
    name: "Лоферы",
    category: "shoes",
    priceNew: "5990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Обновите свой стиль с нашими стильными зимними лоферами из высококачественной натуральной замши. Элегантный черный цвет исключительно подчеркнет вашу индивидуальность. Натуральная замша верха и мягкая шерсть внутри обеспечивают комфорт и тепло даже в самый холодный период года. Почувствуйте уют и стиль в каждом шаге. Сделайте свой выбор в пользу комфорта и элегантности!",
    img2: "/shoes/032/032-Boots-01.jpg",
    img1: "/shoes/032/032-Boots-02.jpg",
    img3: "/shoes/032/032-Boots-03.jpg",
    img5: "/shoes/032/032-Boots-04.jpg",
    img4: "/shoes/032/032-Boots-05.jpg",
    img6: "/shoes/032/032-Boots-06.jpg",
  },
  {
    id: "38",
    name: "Сумка",
    category: "accessories",
    priceNew: "4990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: "",
    color: [
      { name: "Черный", class: "product__color_one" },
      { name: "Кэмел", class: "product__color_two" },
      { name: "Светло-бежевый", class: "product__color_three" },
    ],
    description:
      "Обновите свой гардероб с нашей стильной сумкой из зернистой эко-кожи. Элегантный дизайн и высококачественные материалы (верх и подкладка из эко-кожи) придают этой сумке роскошный вид. В комплекте длинный кожаный ремешок добавляет универсальности и стиля. Почувствуйте себя модной и уверенной в любой ситуации. Сделайте стильный выбор и выделитесь среди толпы!",
    img2: "/accessories/006/006-Bag-01.jpg",
    img1: "/accessories/006/006-Bag-02.jpg",
    img3: "/accessories/006/006-Bag-03.jpg",
    img5: "/accessories/006/006-Bag-04.jpg",
    img4: "/accessories/006/006-Bag-05.jpg",
    img6: "/accessories/006/006-Bag-06.jpg",
  },
  {
    id: "39",
    name: "Сумка мешок",
    category: "accessories",
    priceNew: "3990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: "",
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Обновите свой образ с нашей элегантной черной сумкой-мешком из высококачественной эко-кожи. Её стильный дизайн подчеркивает вашу утонченность. Материал из эко-кожи обеспечивает долговечность и легкость ухода. Эта сумка прекрасно сочетается с любым нарядом. Просторный формат и практичный черный цвет делают её идеальным выбором для повседневного использования. Обновите свой стиль и добавьте шик своему образу!",
    img2: "/accessories/007/007-Bag-01.jpg",
    img1: "/accessories/007/007-Bag-02.jpg",
    img3: "/accessories/007/007-Bag-03.jpg",
    img5: "/accessories/007/007-Bag-04.jpg",
    img4: "/accessories/007/007-Bag-05.jpg",
    img6: "/accessories/007/007-Bag-06.jpg",
  },
  {
    id: "40",
    name: "Туфли",
    category: "shoes",
    priceNew: "3990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Обновите свой образ с нашими стильными черными туфлями из матового текстиля. Их изысканный дизайн и материал верха, выполненный из матового текстиля стрейч, придают им элегантность. Внутренняя часть обуви сделана из мягкого текстиля, а натуральная кожаная стелька обеспечивает высокий уровень комфорта. Эти туфли — не только модный акцент, но и идеальный выбор для комфортного повседневного использования. Добавьте стиль и удобство в каждый ваш шаг!",
    img2: "/shoes/033/033-Boots-01.jpg",
    img1: "/shoes/033/033-Boots-02.jpg",
    img3: "/shoes/033/033-Boots-03.jpg",
    img5: "/shoes/033/033-Boots-04.jpg",
    img4: "/shoes/033/033-Boots-05.jpg",
    img6: "/shoes/033/033-Boots-06.jpg",
  },
  {
    id: "41",
    name: "Сапоги",
    category: "shoes",
    priceNew: "9890 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Освежите свой стиль с нашими элегантными черными сапогами из натуральной замши. Их изысканный дизайн и материал верха из натуральной кожи придают им утонченный вид. Внутренняя часть сапог оснащена мягкой байкой для уюта и тепла. Термоустойчивая резиновая подошва обеспечивает надежное сцепление с поверхностью. Выберите свой размер из разнообразных вариантов, учитывая, что они большемерят. Сделайте выбор в пользу стиля, комфорта и надежности!",
    img2: "/shoes/034/034-Boots-01.jpg",
    img1: "/shoes/034/034-Boots-02.jpg",
    img3: "/shoes/034/034-Boots-03.jpg",
    img5: "/shoes/034/034-Boots-04.jpg",
    img4: "/shoes/034/034-Boots-05.jpg",
    img6: "/shoes/034/034-Boots-06.jpg",
  },
  {
    id: "42",
    name: "Дутики",
    category: "shoes",
    priceNew: "6490 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Белый", class: "product__color_seven" }],
    description:
      "Ощутите невесомость стиля с нашими белоснежными дутиками из натуральной замши и текстиля. Элегантность объединена с практичностью, создавая образ, который привлекает взгляд. Натуральная шерсть внутри дутиков обеспечивает тепло и комфорт, а уникальное сочетание материалов придает им стильный акцент. С широкими возможностями стиля и удобством, эти дутики подчеркнут вашу индивидуальность в любой обстановке. Воплотите свой утонченный вкус в каждом шаге!",
    img2: "/shoes/035/035-Boots-01.jpg",
    img1: "/shoes/035/035-Boots-02.jpg",
    img3: "/shoes/035/035-Boots-03.jpg",
    img5: "/shoes/035/035-Boots-04.jpg",
    img4: "/shoes/035/035-Boots-05.jpg",
    img6: "/shoes/035/035-Boots-06.jpg",
  },
  {
    id: "43",
    name: "Луны",
    category: "shoes",
    priceNew: "6490 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Бежевый", class: "product__color_eight" }],
    description:
      "Погрузитесь в атмосферу уюта с нашими лунами из натуральной замши в песочно-бежевом оттенке. Эти ботинки, с верхом из натуральной замши и кожи, внутри утеплены натуральным мехом и шерстью, обеспечивая максимальный комфорт. Невероятное сочетание стиля и тепла делает их идеальным выбором для холодных дней. Окунитесь в мягкость и элегантность с нашими лунами, созданными для вашего удовольствия и стиля.",
    img2: "/shoes/036/036-Boots-01.jpg",
    img1: "/shoes/036/036-Boots-02.jpg",
    img3: "/shoes/036/036-Boots-03.jpg",
    img5: "/shoes/036/036-Boots-04.jpg",
    img4: "/shoes/036/036-Boots-05.jpg",
    img6: "/shoes/036/036-Boots-06.jpg",
  },
  {
    id: "44",
    name: "Сумка Zoe bag",
    category: "accessories",
    priceNew: "8990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: "",
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Завоюйте внимание своим стильным аксессуаром – сумкой Zoe bag из натуральной кожи. Выбирайте из классического черного, теплого оттенка соленой карамели или нежного пудрового цвета. Верх сумки выполнен из высококачественной натуральной кожи, а внутренняя часть подкладки – из прочного текстиля. В комплекте идет длинный кожаный ремешок, придающий универсальность. С элегантными размерами (25/32 см в длину, 21 см в высоту), эта сумка Zoe bag станет идеальным завершением вашего стильного образа.",
    img2: "/accessories/008/008-Bag-01.jpg",
    img1: "/accessories/008/008-Bag-02.jpg",
    img3: "/accessories/008/008-Bag-03.jpg",
    img5: "/accessories/008/008-Bag-04.jpg",
    img4: "/accessories/008/008-Bag-05.jpg",
    img6: "/accessories/008/008-Bag-06.jpg",
  },
  {
    id: "45",
    name: "Ботинки",
    category: "shoes",
    priceNew: "5990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Эти ботинки изготовлены для тех, кто ценит стиль и комфорт. Их черный цвет и материал верха из натуральной кожи добавляют элегантности вашему образу. Внутренняя часть ботинок, выполненная из мягкой байки, обеспечивает уют и тепло. Термоустойчивая резиновая подошва делает эту обувь идеальным выбором для разнообразных условий. Совмещая стиль, комфорт и надежность, эти ботинки станут вашим верным спутником в повседневных приключениях.",
    img2: "/shoes/037/037-Boots-01.jpg",
    img1: "/shoes/037/037-Boots-02.jpg",
    img3: "/shoes/037/037-Boots-03.jpg",
    img5: "/shoes/037/037-Boots-04.jpg",
    img4: "/shoes/037/037-Boots-05.jpg",
    img6: "/shoes/037/037-Boots-06.jpg",
  },
  {
    id: "46",
    name: "Дутики",
    category: "shoes",
    priceNew: "3990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40", "41"],
    color: [{ name: "Светло-бежевый", class: "product__color_three" }],
    description:
      "Излучайте уют и стиль с нашими короткими дутиками в нежном молочно-бежевом оттенке. Верх из мягкого текстиля обеспечивает комфорт и легкость. Внутри вас ждет натуральная шерсть, создающая тепло и уют в холодные дни. Выбирайте свой размер с учетом того, что эти дутики маломерят (36-41). Совместите моду и тепло в этой универсальной обуви, добавив легкости и стиля вашему повседневному образу.",
    img2: "/shoes/038/038-Boots-01.jpg",
    img1: "/shoes/038/038-Boots-02.jpg",
    img3: "/shoes/038/038-Boots-03.jpg",
    img5: "/shoes/038/038-Boots-04.jpg",
    img4: "/shoes/038/038-Boots-05.jpg",
    img6: "/shoes/038/038-Boots-06.jpg",
  },
  {
    id: "47",
    name: "Рюкзак",
    category: "accessories",
    priceNew: "3990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: "",
    color: [
      { name: "Серый", class: "product__color_eleven" },
      { name: "Белый", class: "product__color_seven" },
    ],
    description:
      "Излучайте стиль с нашим рюкзаком из прессованной кожи, доступным в элегантном темно-сером и белом каменном оттенках. Прессованная кожа создает текстурный эффект, добавляя рюкзаку уникальность. Внутренняя часть подкладки выполнена из прочной ткани, обеспечивая долговечность. Современный дизайн и удобная функциональность делают этот рюкзак идеальным спутником для повседневных приключений. Подчеркните свой стиль и универсальность с этим элегантным аксессуаром.",
    img2: "/accessories/009/009-Bag-01.jpg",
    img1: "/accessories/009/009-Bag-02.jpg",
    img3: "/accessories/009/009-Bag-03.jpg",
    img5: "/accessories/009/009-Bag-04.jpg",
    img4: "/accessories/009/009-Bag-05.jpg",
    img6: "/accessories/009/009-Bag-06.jpg",
  },
  {
    id: "48",
    name: "Сумка Zoe",
    category: "accessories",
    priceNew: "8990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: "",
    color: [{ name: "Темно-бежевый", class: "product__color_four" }],
    description:
      "Подчеркните свой стиль с нашей утонченной сумкой ZOE из натуральной кожи. Выберите из нашей коллекции восхитительные оттенки: теплая соленая карамель или нежная пудра. Материал верха из натуральной кожи придает сумке роскошный вид, а подкладка из прочной ткани обеспечивает долговечность. В комплекте съемный длинный ремешок для универсальности. Хлястики на ручке съемные, позволяя вам настраивать ваш стиль. С элегантными размерами (25/32 см в длину, 21 см в высоту), сумка ZOE станет вашим неповторимым аксессуаром, добавляя изысканности в каждый ваш день.",
    img2: "/accessories/010/010-Bag-01.jpg",
    img1: "/accessories/010/010-Bag-02.jpg",
    img3: "/accessories/010/010-Bag-03.jpg",
    img5: "/accessories/010/010-Bag-04.jpg",
    img4: "/accessories/010/010-Bag-05.jpg",
    img6: "/accessories/010/010-Bag-06.jpg",
  },
  {
    id: "49",
    name: "Лоферы",
    category: "shoes",
    priceNew: "5990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40", "41"],
    color: [
      { name: "Черный", class: "product__color_one" },
      { name: "Темно-бежевый", class: "product__color_four" },
      { name: "Белый", class: "product__color_seven" },
    ],
    description:
      "Обновите свой зимний стиль с нашими лоферами из натуральной замши. Выбирайте из утонченных оттенков: черный, темно-бежевый или белый камень. Материал верха из натуральной замши придает обуви роскошный вид. Внутренняя часть утеплена натуральной шерстью для максимального комфорта в холодные дни. Термоустойчивая резиновая подошва обеспечивает надежное сцепление с поверхностью. С элегантными лоферами подчеркните свой стиль, наслаждаясь теплом и удобством в каждом шаге.",
    img2: "/shoes/039/039-Boots-01.jpg",
    img1: "/shoes/039/039-Boots-02.jpg",
    img3: "/shoes/039/039-Boots-03.jpg",
    img5: "/shoes/039/039-Boots-04.jpg",
    img4: "/shoes/039/039-Boots-05.jpg",
    img6: "/shoes/039/039-Boots-06.jpg",
  },
  {
    id: "50",
    name: "Сапоги",
    category: "shoes",
    priceNew: "6990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Погрузитесь в элегантность и комфорт с нашими сапогами-трубами из натуральной кожи. Глубокий черный цвет придает им изысканный вид. Натуральная кожа верха обеспечивает стойкость и элегантность, а прессованная кожа внутри добавляет комфорт. Облегченная подошва с противоскользящим рельефным узором обеспечивает безопасность и устойчивость. Размеры 36-40, но учтите, что они маломерят. Ощутите легкость и стиль в каждом шаге с нашими стильными сапогами.",
    img2: "/shoes/040/040-Boots-01.jpg",
    img1: "/shoes/040/040-Boots-02.jpg",
    img3: "/shoes/040/040-Boots-03.jpg",
    img5: "/shoes/040/040-Boots-04.jpg",
    img4: "/shoes/040/040-Boots-05.jpg",
    img6: "/shoes/040/040-Boots-06.jpg",
  },
  {
    id: "51",
    name: "Ботфорты",
    category: "shoes",
    priceNew: "4790 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Обретите силу стиля с нашими ботфортами Maxi из стрейч-эко-кожи. Глубокий черный цвет придает им элегантность. Материал из стрейч-эко-кожи обеспечивает комфортное облегание, а внутренняя часть, выполненная из мягкой байки, добавляет уюта. Подчеркните свой образ с высоким каблуком высотой 9-10 см. Доступные размеры: 36-40. Создайте великолепный стиль с нашими ботфортами Maxi, ощущая элегантность и комфорт на каждом шагу.",
    img2: "/shoes/041/041-Boots-01.jpg",
    img1: "/shoes/041/041-Boots-02.jpg",
    img3: "/shoes/041/041-Boots-03.jpg",
    img5: "/shoes/041/041-Boots-04.jpg",
    img4: "/shoes/041/041-Boots-05.jpg",
    img6: "/shoes/041/041-Boots-06.jpg",
  },
  {
    id: "52",
    name: "Рюкзак",
    category: "accessories",
    priceNew: "3990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: "",
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Освежите свой стиль с нашим рюкзаком из прессованной кожи в стильном черно-денимовом оттенке. Материал верха из прессованной кожи придает рюкзаку современный и элегантный вид. Внутренний тканевый подклад добавляет удобство и практичность. Черный деним создает интересный контраст, делая этот рюкзак не только функциональным, но и стильным аксессуаром. Подчеркните свой уникальный стиль с нашим рюкзаком, который сочетает в себе моду и функциональность.",
    img2: "/accessories/011/011-Bag-01.jpg",
    img1: "/accessories/011/011-Bag-02.jpg",
    img3: "/accessories/011/011-Bag-03.jpg",
    img5: "/accessories/011/011-Bag-04.jpg",
    img4: "/accessories/011/011-Bag-05.jpg",
    img6: "/accessories/011/011-Bag-06.jpg",
  },
  {
    id: "53",
    name: "Ботинки",
    category: "shoes",
    priceNew: "4990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Откройте для себя универсальность и стиль с нашими черными ботинками из натуральной кожи. Эти элегантные ботинки предлагают комбинацию изысканности и комфорта. Натуральная кожа верха и тканевый подклад внутри обеспечивают высокий уровень качества и удобства. Термоустойчивая резиновая подошва обеспечивает надежное сцепление с поверхностью. Размеры доступны в диапазоне от 36 до 40. Отлично подходят для повседневного использования, эти ботинки станут стильным и практичным дополнением к вашему гардеробу.",
    img2: "/shoes/042/042-Boots-01.jpg",
    img1: "/shoes/042/042-Boots-02.jpg",
    img3: "/shoes/042/042-Boots-03.jpg",
    img5: "/shoes/042/042-Boots-04.jpg",
    img4: "/shoes/042/042-Boots-05.jpg",
    img6: "/shoes/042/042-Boots-06.jpg",
  },
  {
    id: "54",
    name: "Ботинки",
    category: "shoes",
    priceNew: "4990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Погрузитесь в элегантность с нашими черными ботинками из натуральной кожи. Прекрасный черный цвет подчеркивает их стиль. Натуральная кожа верха и тканевый подклад внутри обеспечивают комфорт и долговечность. Термоустойчивая резиновая подошва добавляет безопасность и устойчивость. Размеры доступны в диапазоне от 36 до 40, подходя для различных предпочтений. Сочетая стиль и функциональность, эти ботинки идеальны для создания изысканного образа в любом сезоне.",
    img2: "/shoes/043/043-Boots-01.jpg",
    img1: "/shoes/043/043-Boots-02.jpg",
    img3: "/shoes/043/043-Boots-03.jpg",
    img5: "/shoes/043/043-Boots-04.jpg",
    img4: "/shoes/043/043-Boots-05.jpg",
    img6: "/shoes/043/043-Boots-06.jpg",
  },
  {
    id: "55",
    name: "Сумка NOBIRKIN",
    category: "accessories",
    priceNew: "4990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: "",
    color: [
      { name: "Черный", class: "product__color_one" },
      { name: "Бежевый", class: "product__color_eight" },
      { name: "Темно-бежевый", class: "product__color_four" },
    ],
    description:
      "NOBIRKIN – идеальная сумка для стильных образов. Выполненная из эко-кожи с подкладкой того же материала, она сочетает комфорт и стиль. В комплекте длинный ремешок для удобства. Размер 30 см * 22 см подходит для повседневного использования. Выберите свой оттенок: черный, бежевый или соленая карамель, а также фурнитуру - золото или серебро. NOBIRKIN – идеальный выбор для элегантности и удобства.",
    img2: "/accessories/012/012-Bag-01.jpg",
    img1: "/accessories/012/012-Bag-02.jpg",
    img3: "/accessories/012/012-Bag-03.jpg",
    img5: "/accessories/012/012-Bag-04.jpg",
    img4: "/accessories/012/012-Bag-05.jpg",
    img6: "/accessories/012/012-Bag-06.jpg",
  },
  {
    id: "56",
    name: "Казаки",
    category: "shoes",
    priceNew: "6690 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [
      { name: "Темно-бежевый", class: "product__color_four" },
      { name: "Серый", class: "product__color_eleven" },
    ],
    description:
      "Казаки - воплощение стиля и комфорта. Изготовлены из натуральной замши для элегантного внешнего вида. Внутренняя часть выполнена из натуральной кожи или текстиля, обеспечивая максимальный комфорт. Эти ботинки идеально подходят для повседневного использования, сочетая в себе модный дизайн и высокое качество материалов. Подчеркните свой стиль и наслаждайтесь комфортом с казаками.",
    img2: "/shoes/044/044-Boots-01.jpg",
    img1: "/shoes/044/044-Boots-02.jpg",
    img3: "/shoes/044/044-Boots-03.jpg",
    img5: "/shoes/044/044-Boots-04.jpg",
    img4: "/shoes/044/044-Boots-05.jpg",
    img6: "/shoes/044/044-Boots-06.jpg",
  },
  {
    id: "57",
    name: "Сумка NOKELLI",
    category: "accessories",
    priceNew: "8490 ₽",
    priceOld: "",
    country: "Китай",
    sizes: "",
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Сумка NOKELLI - воплощение роскоши и функциональности. Изготовлена из высококачественной натуральной кожи как снаружи, так и внутри, обеспечивая элегантный внешний вид и высокий комфорт. В комплекте поставляется длинный ремешок, ключ и замок для удобства использования. Черный цвет и компактный размер 28 см подчеркивают стиль. NOKELLI - выбор для тех, кто ценит исключительное качество и стиль в каждой детали.",
    img2: "/accessories/013/013-Bag-01.jpg",
    img1: "/accessories/013/013-Bag-02.jpg",
    img3: "/accessories/013/013-Bag-03.jpg",
    img5: "/accessories/013/013-Bag-04.jpg",
    img4: "/accessories/013/013-Bag-05.jpg",
    img6: "/accessories/013/013-Bag-06.jpg",
  },
  {
    id: "58",
    name: "Сумка Birkin Denim",
    category: "accessories",
    priceNew: "6590 ₽",
    priceOld: "",
    country: "Китай",
    sizes: "",
    color: [
      { name: "Черный", class: "product__color_one" },
      { name: "Серый", class: "product__color_eleven" },
    ],
    description:
      "Сумка Birkin Denim - это воплощение современного шика и непревзойденного стиля. Верх сумки выполнен из прочного деним-джинса, а подкладка из текстиля создает легкий и модный образ. В комплекте сумки идут длинный ремешок, ключ и замочек для вашего удобства. Компактные размеры в 40 см подчеркивают функциональность и элегантность, делая ее идеальным выбором для повседневного использования. А еще, при покупке сумки вы получаете в подарок цепочку обвес. Birkin Denim - ваш выбор для выдающегося стиля и практичности.",
    img2: "/accessories/014/014-Bag-01.jpg",
    img1: "/accessories/014/014-Bag-02.jpg",
    img3: "/accessories/014/014-Bag-03.jpg",
    img5: "/accessories/014/014-Bag-04.jpg",
    img4: "/accessories/014/014-Bag-051.jpg",
    img6: "/accessories/014/014-Bag-061.jpg",
  },
  {
    id: "59",
    name: "Сумка",
    category: "accessories",
    priceNew: "4990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: "",
    color: [
      { name: "Черный", class: "product__color_one" },
      { name: "Бежевый", class: "product__color_eight" },
    ],
    description:
      "Откройте для себя стиль и удобство с нашей экологически чистой сумкой! Выполненная из высококачественной эко-кожи как снаружи, так и внутри, она сочетает в себе роскошь и заботу о природе. В комплект входит длинный ремешок и замочек, обеспечивая максимальную универсальность. Компактный размер 35 см - идеальное сочетание функциональности и стиля для вашего повседневного образа!",
    img2: "/accessories/015/015-Bag-01.jpg",
    img1: "/accessories/015/015-Bag-02.jpg",
    img3: "/accessories/015/015-Bag-03.jpg",
    img5: "/accessories/015/015-Bag-04.jpg",
    img4: "/accessories/015/015-Bag-05.jpg",
    img6: "/accessories/015/015-Bag-06.jpg",
  },
  {
    id: "60",
    name: "Ботфорты",
    category: "shoes",
    priceNew: "5990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Эксклюзивные ботфорты – олицетворение комфорта и стиля! Изготовлены из эко-кожи стрейч, обеспечивая идеальную посадку и гибкость. Внутри - текстиль стрейч, придающий уют и мягкость. Удобный каблук высотой 6 см создает элегантность без ущерба для комфорта. Эти ботфорты - не просто обувь, это образ жизни, в котором сочетаются стиль и непревзойденный комфорт.",
    img2: "/shoes/045/045-Boots-01.jpg",
    img1: "/shoes/045/045-Boots-02.jpg",
    img3: "/shoes/045/045-Boots-03.jpg",
    img5: "/shoes/045/045-Boots-04.jpg",
    img4: "/shoes/045/045-Boots-05.jpg",
    img6: "/shoes/045/045-Boots-06.jpg",
  },
  {
    id: "61",
    name: "Сапоги трубы",
    category: "shoes",
    priceNew: "8490 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Окунитесь в роскошь с нашими эксклюзивными сапогами трубы! Изготовленные из натуральной кожи с внутренней частью из натуральной кожи или текстиля, они обеспечивают невероятный комфорт и стиль. Термоустойчивая резиновая подошва гарантирует прочность и безопасность. В комплекте сменные утепленные стельки, придающие дополнительный уют. Эти сапоги - воплощение утонченности и инноваций в мире обуви.",
    img2: "/shoes/046/046-Boots-01.jpg",
    img1: "/shoes/046/046-Boots-02.jpg",
    img3: "/shoes/046/046-Boots-03.jpg",
    img5: "/shoes/046/046-Boots-04.jpg",
    img4: "/shoes/046/046-Boots-05.jpg",
    img6: "/shoes/046/046-Boots-06.jpg",
  },
  {
    id: "62",
    name: "Угги",
    category: "shoes",
    priceNew: "4990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40", "41"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Погрузитесь в роскошь и тепло с нашими эксклюзивными уггами! Изготовленные из натуральной замши с мягкой натуральной шерстью внутри, они обеспечивают непревзойденный комфорт в холодные дни. Термоустойчивая резиновая подошва гарантирует надежное сцепление. Съемный ремешок добавляет утонченности. Размеры 36-41 (маломерят). Очаровывайтесь темным графитовым оттенком, создавая стильный образ для любого сезона.",
    img2: "/shoes/047/047-Boots-01.jpg",
    img1: "/shoes/047/047-Boots-02.jpg",
    img3: "/shoes/047/047-Boots-03.jpg",
    img5: "/shoes/047/047-Boots-04.jpg",
    img4: "/shoes/047/047-Boots-05.jpg",
    img6: "/shoes/047/047-Boots-06.jpg",
  },
  {
    id: "63",
    name: "Сапоги трубы на каблуке",
    category: "shoes",
    priceNew: "5990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Исключительные сапоги трубы на каблуке - символ стиля и комфорта. Их элегантный дизайн, выполненный из экологически чистой кожзаменителя, придает изысканность вашему образу. Внутри также эко-кожа, обеспечивая комфорт в течение всего дня. Высокий каблук высотой 9 см подчеркивает женственность и уверенность. Покажите свой стиль с этими эксклюзивными сапогами, представляющими собой идеальное сочетание моды и удобства.",
    img2: "/shoes/048/048-Boots-01.jpg",
    img1: "/shoes/048/048-Boots-02.jpg",
    img3: "/shoes/048/048-Boots-03.jpg",
    img5: "/shoes/048/048-Boots-04.jpg",
    img4: "/shoes/048/048-Boots-05.jpg",
    img6: "/shoes/048/048-Boots-06.jpg",
  },
  {
    id: "64",
    name: "Босоножки",
    category: "shoes",
    priceNew: "3990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Очарование и комфорт в каждом шаге с нашими эксклюзивными босоножками! Изысканный текстиль атлас создает роскошный вид, а стелька из эко-кожи обеспечивает максимальный комфорт. Каблук высотой 9 см придает элегантность, делая их идеальным выбором для особых моментов. Выразите свой стиль с этими босоножками, которые сочетают в себе моду и утонченность, подчеркивая вашу уверенность и стиль.",
    img2: "/shoes/049/049-Boots-01.jpg",
    img1: "/shoes/049/049-Boots-02.jpg",
    img3: "/shoes/049/049-Boots-03.jpg",
    img5: "/shoes/049/049-Boots-04.jpg",
    img4: "/shoes/049/049-Boots-05.jpg",
    img6: "/shoes/049/049-Boots-06.jpg",
  },
  {
    id: "65",
    name: "Сумка NOBIRKIN",
    category: "accessories",
    priceNew: "4990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: "",
    color: [
      { name: "Черный", class: "product__color_one" },
      { name: "Бежевый", class: "product__color_eight" },
    ],
    description:
      "Сумка NOBIRKIN – высший стиль и утонченность в каждой детали. Изготовлена из экологически чистой кожзаменителя как снаружи, так и внутри, подчеркивая наше стремление к устойчивости. Компактные 35 см - идеальный размер для повседневного использования. В комплекте длинный ремешок, замочек и ключ, придающие функциональность и шарм. NOBIRKIN – идеальное сочетание стиля и ответственности.",
    img2: "/accessories/016/016-Bag-01.jpg",
    img1: "/accessories/016/016-Bag-02.jpg",
    img3: "/accessories/016/016-Bag-03.jpg",
    img5: "/accessories/016/016-Bag-04.jpg",
    img4: "/accessories/016/016-Bag-05.jpg",
    img6: "/accessories/016/016-Bag-06.jpg",
  },
  {
    id: "66",
    name: "Казаки",
    category: "shoes",
    priceNew: "4990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40", "41"],
    color: [{ name: "Темно-бежевый", class: "product__color_four" }],
    description:
      "Эти эксклюзивные казаки - воплощение элегантности и комфорта. Изысканная натуральная замша создает роскошный внешний вид, подчеркивая ваш стиль. Внутренняя отделка из текстиля обеспечивает мягкость и комфорт при каждом шаге. Эти казаки - не просто обувь, это выражение вашей индивидуальности через качество и стиль, подчеркивая ваш неповторимый образ.",
    img2: "/shoes/050/050-Boots-01.jpg",
    img1: "/shoes/050/050-Boots-02.jpg",
    img3: "/shoes/050/050-Boots-03.jpg",
    img5: "/shoes/050/050-Boots-04.jpg",
    img4: "/shoes/050/050-Boots-05.jpg",
    img6: "/shoes/050/050-Boots-06.jpg",
  },
  {
    id: "67",
    name: "Ремешок",
    category: "accessories",
    priceNew: "1790 ₽",
    priceOld: "",
    country: "Китай",
    sizes: "",
    color: [
      { name: "Черный", class: "product__color_one" },
      { name: "Бежевый", class: "product__color_eight" },
    ],
    description:
      "Наши эксклюзивные ремешки - воплощение стиля и удобства. Изготовлены из высококачественной прессованной кожи, обеспечивая надежность и элегантность. Универсальный размер, саморегулируемый, подходит для любого запястья. Выберите из палитры модных оттенков: черный, соленая карамель или пудра, чтобы подчеркнуть ваш индивидуальный стиль. Эти ремешки - идеальное дополнение к вашему образу, придавая ему уникальность и шарм.",
    img2: "/accessories/017/017-Bag-01.jpg",
    img1: "/accessories/017/017-Bag-02.jpg",
    img3: "/accessories/017/017-Bag-03.jpg",
    img5: "/accessories/017/017-Bag-04.jpg",
    img4: "/accessories/017/017-Bag-05.jpg",
    img6: "/accessories/017/017-Bag-06.jpg",
  },
  {
    id: "68",
    name: "Угги с открытой пяткой",
    category: "shoes",
    priceNew: "1990 ₽",
    priceOld: "4290 ₽",
    country: "Китай",
    sizes: ["36", "37", "38"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Эти эксклюзивные угги с открытой пяткой – идеальное сочетание стиля и комфорта. Натуральная замша создает роскошный вид, а внутренняя эко-шерсть обеспечивает мягкость и тепло. Размеры 36/38 (маломерят), обеспечивают идеальную посадку. Уникальный дизайн подчеркнет вашу индивидуальность, делая эти угги стильным акцентом в вашем гардеробе. Ощутите удовольствие от каждого шага в этих уггах высокого класса.",
    img2: "/shoes/051/051-Boots-01.jpg",
    img1: "/shoes/051/051-Boots-02.jpg",
    img3: "/shoes/051/051-Boots-03.jpg",
    img5: "/shoes/051/051-Boots-04.jpg",
    img4: "/shoes/051/051-Boots-05.jpg",
    img6: "/shoes/051/051-Boots-06.jpg",
  },
  {
    id: "69",
    name: "Ботинки",
    category: "shoes",
    priceNew: "3990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Эти эксклюзивные ботинки – идеальное сочетание стиля и инноваций. Изготовленные из эко-кожи верха и мягкого текстиля внутри, они предлагают безупречный комфорт и элегантность. Термоустойчивая резиновая подошва обеспечивает прочность и надежное сцепление. Эти ботинки - ваш выбор для стильного образа и уверенности на каждом шагу. Почувствуйте роскошь в каждой детали!",
    img2: "/shoes/052/052-Boots-01.jpg",
    img1: "/shoes/052/052-Boots-02.jpg",
    img3: "/shoes/052/052-Boots-03.jpg",
    img5: "/shoes/052/052-Boots-04.jpg",
    img4: "/shoes/052/052-Boots-05.jpg",
    img6: "/shoes/052/052-Boots-06.jpg",
  },
  {
    id: "70",
    name: "Угги короткие ultra mini",
    category: "shoes",
    priceNew: "5490 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40", "41"],
    color: [
      { name: "Черный", class: "product__color_one" },
      { name: "Бежевый", class: "product__color_eight" },
    ],
    description:
      "Эти эксклюзивные ультракороткие угги - воплощение стиля и комфорта. Натуральная замша верха создает элегантный внешний вид, а натуральная шерсть внутри обеспечивает максимальное утепление. С термоустойчивой резиновой подошвой, они становятся идеальным выбором для любых условий. Эти угги ultra mini - ваш путь к стильному и теплому образу. Воплотите в жизнь роскошь и уют с каждым шагом.",
    img2: "/shoes/053/053-Boots-01.jpg",
    img1: "/shoes/053/053-Boots-02.jpg",
    img3: "/shoes/053/053-Boots-03.jpg",
    img5: "/shoes/053/053-Boots-04.jpg",
    img4: "/shoes/053/053-Boots-05.jpg",
    img6: "/shoes/053/053-Boots-06.jpg",
  },
  {
    id: "71",
    name: "Сумка в стиле the row maxi",
    category: "accessories",
    priceNew: "8990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: "",
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Погрузитесь в мир роскоши с нашей эксклюзивной сумкой в стиле The Row Maxi. Изготовленная из высококачественной натуральной кожи с текстильным подкладом, она сочетает в себе изысканный дизайн и непревзойденное качество. Черный цвет добавляет элегантности и универсальности вашему образу. Эта сумка Maxi - воплощение стиля и утонченности, подчеркивая вашу неповторимую эстетику и роскошь.",
    img2: "/accessories/018/018-Bag-01.jpg",
    img1: "/accessories/018/018-Bag-02.jpg",
    img3: "/accessories/018/018-Bag-03.jpg",
    img5: "/accessories/018/018-Bag-04.jpg",
    img4: "/accessories/018/018-Bag-05.jpg",
    img6: "/accessories/018/018-Bag-06.jpg",
  },
  {
    id: "72",
    name: "Сумка в стиле the row mini",
    category: "accessories",
    priceNew: "7790 ₽",
    priceOld: "",
    country: "Китай",
    sizes: "",
    color: [
      { name: "Черный", class: "product__color_one" },
      { name: "Бежевый", class: "product__color_eight" },
    ],
    description:
      "Эксклюзивная сумка в стиле The Row Mini - воплощение элегантности и роскоши. Изготовленная из высококачественной натуральной кожи, она выделяется своим безупречным стилем и изысканным дизайном. Тканевый подклад обеспечивает дополнительную прочность и долговечность. Эта мини-сумка станет вашим незаменимым аксессуаром для повседневного использования, подчеркивая ваш утонченный вкус и статус.",
    img2: "/accessories/019/019-Bag-01.jpg",
    img1: "/accessories/019/019-Bag-02.jpg",
    img3: "/accessories/019/019-Bag-03.jpg",
    img5: "/accessories/019/019-Bag-04.jpg",
    img4: "/accessories/019/019-Bag-05.jpg",
    img6: "/accessories/019/019-Bag-06.jpg",
  },
  {
    id: "73",
    name: "Кроссовки",
    category: "shoes",
    priceNew: "4590 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40", "41"],
    color: [{ name: "Белый", class: "product__color_seven" }],
    description:
      "Погрузитесь в мир стиля и комфорта с нашими эксклюзивными кроссовками. Изготовленные из натуральной кожи или замши, они сочетают в себе прочность и элегантность. Текстильный подклад внутри обеспечивает мягкость и вентиляцию, даря вашим стопам непревзойденный комфорт. Эти кроссовки – идеальный выбор для активного образа жизни, подчеркивая ваш стиль и вкус. Ощутите качество и утонченность в каждой детали.",
    img2: "/shoes/054/054-Boots-01.jpg",
    img1: "/shoes/054/054-Boots-02.jpg",
    img3: "/shoes/054/054-Boots-03.jpg",
    img5: "/shoes/054/054-Boots-04.jpg",
    img4: "/shoes/054/054-Boots-05.jpg",
    img6: "/shoes/054/054-Boots-06.jpg",
  },
  {
    id: "74",
    name: "Челси",
    category: "shoes",
    priceNew: "5990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Эти эксклюзивные ботинки Челси - воплощение изысканности и стиля. Изготовленные из натуральной кожи, они обеспечивают превосходное качество и долговечность. Текстильный подклад внутри обеспечивает комфорт и мягкость при каждом шаге. Челси - это не просто обувь, это идеальный выбор для тех, кто ценит высокое качество и безупречный стиль. Погрузитесь в роскошь с этими эксклюзивными ботинками.",
    img2: "/shoes/055/055-Boots-01.jpg",
    img1: "/shoes/055/055-Boots-02.jpg",
    img3: "/shoes/055/055-Boots-03.jpg",
    img5: "/shoes/055/055-Boots-04.jpg",
    img4: "/shoes/055/055-Boots-05.jpg",
    img6: "/shoes/055/055-Boots-06.jpg",
  },
  {
    id: "75",
    name: "Ботильоны",
    category: "shoes",
    priceNew: "3990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Черный", class: "product__color_one" }],
    description:
      "Наши эксклюзивные ботильоны представляют собой идеальное сочетание стиля и удобства. Изготовленные из высококачественной эко-кожи, они обеспечивают элегантный внешний вид и прочность. Текстильный подклад внутри создает мягкое и комфортное ощущение при носке. Эти ботильоны - ваш надежный выбор для завершения модного образа и комфорта на протяжении всего дня. Почувствуйте роскошь и уверенность в каждом шаге!",
    img2: "/shoes/056/056-Boots-01.jpg",
    img1: "/shoes/056/056-Boots-02.jpg",
    img3: "/shoes/056/056-Boots-03.jpg",
    img5: "/shoes/056/056-Boots-04.jpg",
    img4: "/shoes/056/056-Boots-05.jpg",
    img6: "/shoes/056/056-Boots-06.jpg",
  },
  {
    id: "76",
    name: "Сумка Birkin Denim",
    category: "accessories",
    priceNew: "5990 ₽",
    priceOld: "",
    country: "Китай",
    sizes: "",
    color: [
      { name: "Черный", class: "product__color_one" },
      { name: "Серый", class: "product__color_eleven" },
    ],
    description:
      "Сумка Birkin Denim - это воплощение современного шика и безупречного стиля. Её верх выполнен из прочного деним-джинса, а подкладка из текстиля придает легкость и модный вид. В комплекте идет длинный ремешок, ключ и замочек для максимального удобства. Компактные размеры в 30 см подчеркивают её функциональность и элегантность, делая её идеальным выбором для повседневного использования. При покупке вы также получаете в подарок цепочку обвес. Неотразимый стиль и практичность - вот что представляет Birkin Denim.",
    img1: "/accessories/014/014-Bag-01.jpg",
    img2: "/accessories/014/014-Bag-02.jpg",
    img3: "/accessories/014/014-Bag-03.jpg",
    img4: "/accessories/014/014-Bag-04.jpg",
    img5: "/accessories/014/014-Bag-051.jpg",
    img6: "/accessories/014/014-Bag-061.jpg",
  },
  {
    id: "77",
    name: "Сумка Birkin Denim",
    category: "accessories",
    priceNew: "6190 ₽",
    priceOld: "",
    country: "Китай",
    sizes: "",
    color: [
      { name: "Черный", class: "product__color_one" },
      { name: "Серый", class: "product__color_eleven" },
    ],
    description:
      "Сумка Birkin Denim - это воплощение современного шика и непревзойденного стиля. Ее верх выполнен из прочного деним-джинса, а подкладка из текстиля создает легкий и модный образ. В комплекте сумки идут длинный ремешок, ключ и замочек для вашего удобства. Компактные размеры в 35 см подчеркивают функциональность и элегантность, делая ее идеальным выбором для повседневного использования. А теперь при покупке вы также получаете цепочку обвес в подарок. Birkin Denim - ваш выбор для выдающегося стиля и практичности.",
    img6: "/accessories/014/014-Bag-01.jpg",
    img5: "/accessories/014/014-Bag-02.jpg",
    img4: "/accessories/014/014-Bag-03.jpg",
    img3: "/accessories/014/014-Bag-04.jpg",
    img2: "/accessories/014/014-Bag-051.jpg",
    img1: "/accessories/014/014-Bag-061.jpg",
  },
  {
    id: "78",
    name: "Ботинки",
    category: "shoes",
    priceNew: "7490 ₽",
    priceOld: "",
    country: "Китай",
    sizes: ["36", "37", "38", "39", "40"],
    color: [{ name: "Светло-бежевый", class: "product__color_three" }],
    description:
      "Эти эксклюзивные ботинки из натуральной замши сочетают в себе стиль и премиальное качество. Верх из мягкой натуральной замши создает элегантный внешний вид, а внутренняя отделка из натуральной кожи обеспечивает непревзойденный комфорт и долговечность. Эти ботинки идеально подходят для стильных образов, придавая вам уверенность и роскошь в каждом шаге. Идеальный выбор для тех, кто ценит качество и комфорт.",
    img1: "/shoes/057/057-Boots-01.jpeg",
    img2: "/shoes/057/057-Boots-02.jpeg",
    img3: "/shoes/057/057-Boots-03.jpeg",
    img4: "/shoes/057/057-Boots-04.jpeg",
    img5: "/shoes/057/057-Boots-05.jpeg",
    img6: "/shoes/057/057-Boots-06.jpeg",
  },
];
