import React from 'react';
import {
	SHOECARE_POWER_RESTORATION_COLOR,
	SHOECARE_POWER_RESTORATION_COLOR_TEXT,
	SHOECARE_CLEAN,
	SHOECARE_CLEAN_TEXT,
	SHOECARE_CLEANING_POWER_RECOVERY,
	SHOECARE_CLEANING_POWER_RECOVERY_TEXT,
	SHOECARE_GLASSAGE,
	SHOECARE_GLASSAGE_TEXT,
	SHOECARE_SMOOTHSKIN,
	SHOECARE_SMOOTHSKIN_TEXT,
	SHOECARE_TEXT,
	SHOECARE_CLEANING_POWER_RECOVERY_TEXT2TH,
	SHOECARE_POWER_RESTORATION_COLOR_TEXT2TH,
	SHOECARE_GLASSAGE_TEXT_PART2,
	SHOECARE_GLASSAGE_TEXT_PART3,
	SHOECARE_GLASSAGE_TEXT_PART4,
	SHOECARE_GLASSAGE_TEXT_PART5,
	SHOECARE_SUEDE_NUBUCK,
	SHOECARE_SUEDE_NUBUCK_TEXT,
	SHOECARE_SUEDE_NUBUCK_TEXT_PART2,
	SHOECARE_SUEDE_NUBUCK_TEXT_PART3,
	SHOECARE_SUEDE_NUBUCK_TEXT_PART4,
	SHOECARE_MOISTURE_PROTECTION,
	SHOECARE_MOISTURE_PROTECTION_TEXT,
	SHOECARE_MOISTURE_PROTECTION_TEXT2TH,
	SHOECARE_PATENT_LEATHER,
	SHOECARE_PATENT_LEATHER_TEXT,
	SHOECARE_PATENT_LEATHER_TEXT2TH,
	SHOECARE_PATENT_LEATHER_CLEANING,
	SHOECARE_PATENT_LEATHER_CLEANING_TEXT,
	SHOECARE_PATENT_LEATHER_RESTORATION,
	SHOECARE_PATENT_LEATHER_RESTORATION_TEXT,
	SHOECARE_PATENT_LEATHER_RESTORATION_TEXT2TH,
	SHOECARE_PATENT_LEATHER_POLISHING,
	SHOECARE_PATENT_LEATHER_POLISHING_TEXT,
	SHOECARE_PATENT_LEATHER_POLISHING_TEXT2TH,
	SHOECARE_NUBUCK,
	SHOECARE_NUBUCK_TEXT,
	SHOECARE_DRY_CLEANING,
	SHOECARE_DRY_CLEANING_TEXT,
	SHOECARE_WET_CLEANING,
	SHOECARE_WET_CLEANING_TEXT,
	SHOECARE_POWER,
	SHOECARE_POWER_TEXT,
	SHOECARE_POLISHING,
	SHOECARE_POLISHING_TEXT,
	SHOECARE_POLISHING_TEXT_PART2,
	SHOECARE_POLISHING_TEXT_PART3,
	SHOECARE_ECO_COAT,
	SHOECARE_ECO_COAT_TEXT,
	SHOECARE_ECO_COAT_CLEAN,
	SHOECARE_ECO_COAT_CLEAN_TEXT,
	SHOECARE_ECO_COAT_CLEAN_TEXT2TH,
	SHOECARE_ECO_COAT_PROTECTION,
	SHOECARE_ECO_COAT_PROTECTION_TEXT, SHOECARE_ECO_COAT_CARING, SHOECARE_ECO_COAT_CARING_TEXT,
} from "../../utils/constants";

export default function Shoecare() {
	return (
		
		<main className="content">
			<h2 className="content__subtitle">{SHOECARE_TEXT}</h2>
			<section className="shipping">
				
				<article className="shipping__city">
					<div className="shipping__info">
						<h3 className="content__subheading content__subheading-bold">{SHOECARE_SMOOTHSKIN}</h3>
						<ul>
							<li className="content__text">{SHOECARE_SMOOTHSKIN_TEXT}</li>
						</ul>
						<h3 className="content__subheading">{SHOECARE_CLEAN}</h3>
						<ul>
							<li className="content__text">{SHOECARE_CLEAN_TEXT}</li>
						</ul>
						<h3 className="content__subheading">{SHOECARE_CLEANING_POWER_RECOVERY}</h3>
						<ul>
							<li className="content__text">{SHOECARE_CLEANING_POWER_RECOVERY_TEXT}</li>
							<li className="content__text">{SHOECARE_CLEANING_POWER_RECOVERY_TEXT2TH}</li>
						</ul>
						<h3 className="content__subheading">{SHOECARE_POWER_RESTORATION_COLOR}</h3>
						<ul>
							<li className="content__text">{SHOECARE_POWER_RESTORATION_COLOR_TEXT}</li>
							<li className="content__text">{SHOECARE_POWER_RESTORATION_COLOR_TEXT2TH}</li>
						</ul>
						<h3 className="content__subheading">{SHOECARE_GLASSAGE}</h3>
						<ul>
							<li className="content__text">{SHOECARE_GLASSAGE_TEXT}</li>
							<li className="content__text">{SHOECARE_GLASSAGE_TEXT_PART2}</li>
							<li className="content__text">{SHOECARE_GLASSAGE_TEXT_PART3}</li>
							<li className="content__text">{SHOECARE_GLASSAGE_TEXT_PART4}</li>
							<li className="content__text">{SHOECARE_GLASSAGE_TEXT_PART5}</li>
						</ul>
					</div>
				</article>
				
				<article className="shipping__city">
					<div className="shipping__info">
						<h3 className="content__subheading content__subheading-bold">{SHOECARE_SUEDE_NUBUCK}</h3>
						<ul>
							<li className="content__text">{SHOECARE_SUEDE_NUBUCK_TEXT}</li>
							<li className="content__text">{SHOECARE_SUEDE_NUBUCK_TEXT_PART2}</li>
							<li className="content__text">{SHOECARE_SUEDE_NUBUCK_TEXT_PART3}</li>
							<li className="content__text">{SHOECARE_SUEDE_NUBUCK_TEXT_PART4}</li>
						</ul>
						<h3 className="content__subheading">{SHOECARE_MOISTURE_PROTECTION}</h3>
						<ul>
							<li className="content__text">{SHOECARE_MOISTURE_PROTECTION_TEXT}</li>
							<li className="content__text">{SHOECARE_MOISTURE_PROTECTION_TEXT2TH}</li>
						</ul>
					</div>
				</article>
				
				<article className="shipping__city">
					<div className="shipping__info">
						<h3 className="content__subheading content__subheading-bold">{SHOECARE_PATENT_LEATHER}</h3>
						<ul>
							<li className="content__text">{SHOECARE_PATENT_LEATHER_TEXT}</li>
							<li className="content__text">{SHOECARE_PATENT_LEATHER_TEXT2TH}</li>
						</ul>
						<h3 className="content__subheading">{SHOECARE_PATENT_LEATHER_CLEANING}</h3>
						<ul>
							<li className="content__text">{SHOECARE_PATENT_LEATHER_CLEANING_TEXT}</li>
						</ul>
						<h3 className="content__subheading">{SHOECARE_PATENT_LEATHER_RESTORATION}</h3>
						<ul>
							<li className="content__text">{SHOECARE_PATENT_LEATHER_RESTORATION_TEXT}</li>
							<li className="content__text">{SHOECARE_PATENT_LEATHER_RESTORATION_TEXT2TH}</li>
						</ul>
						<h3 className="content__subheading">{SHOECARE_PATENT_LEATHER_POLISHING}</h3>
						<ul>
							<li className="content__text">{SHOECARE_PATENT_LEATHER_POLISHING_TEXT}</li>
							<li className="content__text">{SHOECARE_PATENT_LEATHER_POLISHING_TEXT2TH}</li>
						</ul>
					</div>
				</article>
				
				<article className="shipping__city">
					<div className="shipping__info">
						<h3 className="content__subheading content__subheading-bold">{SHOECARE_NUBUCK}</h3>
						<ul>
							<li className="content__text">{SHOECARE_NUBUCK_TEXT}</li>
						</ul>
						<h3 className="content__subheading">{SHOECARE_DRY_CLEANING}</h3>
						<ul>
							<li className="content__text">{SHOECARE_DRY_CLEANING_TEXT}</li>
						</ul>
						<h3 className="content__subheading">{SHOECARE_WET_CLEANING}</h3>
						<ul>
							<li className="content__text">{SHOECARE_WET_CLEANING_TEXT}</li>
						</ul>
						<h3 className="content__subheading">{SHOECARE_POWER}</h3>
						<ul>
							<li className="content__text">{SHOECARE_POWER_TEXT}</li>
						</ul>
						<h3 className="content__subheading">{SHOECARE_POLISHING}</h3>
						<ul>
							<li className="content__text">{SHOECARE_POLISHING_TEXT}</li>
							<li className="content__text">{SHOECARE_POLISHING_TEXT_PART2}</li>
							<li className="content__text">{SHOECARE_POLISHING_TEXT_PART3}</li>
						</ul>
					</div>
				</article>
				
				<article className="shipping__city">
					<div className="shipping__info">
						<h3 className="content__subheading content__subheading-bold">{SHOECARE_ECO_COAT}</h3>
						<ul>
							<li className="content__text">{SHOECARE_ECO_COAT_TEXT}</li>
						</ul>
						<h3 className="content__subheading">{SHOECARE_ECO_COAT_CLEAN}</h3>
						<ul>
							<li className="content__text">{SHOECARE_ECO_COAT_CLEAN_TEXT}</li>
							<li className="content__text">{SHOECARE_ECO_COAT_CLEAN_TEXT2TH}</li>
						</ul>
						<h3 className="content__subheading">{SHOECARE_ECO_COAT_PROTECTION}</h3>
						<ul>
							<li className="content__text">{SHOECARE_ECO_COAT_PROTECTION_TEXT}</li>
						</ul>
						<h3 className="content__subheading">{SHOECARE_ECO_COAT_CARING}</h3>
						<ul>
							<li className="content__text">{SHOECARE_ECO_COAT_CARING_TEXT}</li>
						</ul>
					</div>
				</article>
				
			</section>
		
		</main>
	
	)
}