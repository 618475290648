import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate, useLocation } from "react-router-dom";
// import { useNavigate } from 'react-router';

import "./App.css";
import "../../index.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import Main from "../Main/Main";
import About from "../About/About";
import Delivery from "../Delivery/Delivery";
import Contacts from "../Contacts/Contacts";
import Cart from "../Cart/Cart";
import Product from "../Product/Product";
import CardList from "../Main/CardList/CardList";
import Shoecare from "../Shoecare/Shoecare";
import {products} from "../../scripts/productcard";
import Order from "../Order/Order";
import OrderReceived from "../Order/OrderReceived/OrderReceived";
import CurrentUserContext from '../../contexts/CurrentUserContext';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import Error from '../Error/Error';
import Profile from '../User/Profile/Profile';
import Login from '../User/Ligin/Login';
import Register from '../User/Register/Register';

import * as MainApi from '../../utils/MainApi';

import {
	ADD_CARDS_FOR_WIDTH_1280,
	ADD_CARDS_FOR_WIDTH_320,
	CARDS_FOR_WIDTH_1280,
	CARDS_FOR_WIDTH_320,
	CARDS_FOR_WIDTH_768,
	Error_401,
	WIDTH1280,
	WIDTH768,
	WIDTH320,
	WIDTH480,
	ERROR_REGISTER_TEXT,
	ERROR_LOGIN_TEXT,
} from "../../utils/constants";

export default function App() {

	const navigate = useNavigate();
  const location = useLocation();
  const [cards, setCards] = useState([]);
  const [cardsArray, setCardsArray] = useState([]);
  const [cardInput, setCardInput] = useState('');
  const [preloader, setPreloader] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);
  const [errorData, setErrorData] = useState(false);

	useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
		const token = localStorage.getItem('token');
		setLoggedIn(false);
		if (token) {
			setLoggedIn(true);
			authentication(token);
		}
	}, [loggedIn]);

	useEffect(() => {
		if (errorData) {
			setTimeout(()=>{
				setErrorData(false);
			}, 5000);
		}
	})

	useEffect(() => {
		setErrorData(false);
		if (loggedIn && localStorage.getItem('token')) {
			MainApi
				.getUserInfo()
				.then((res) => {
					setCurrentUser(res);
				})
				.catch((err) => {
					console.log(err, ERROR_LOGIN_TEXT);
					setErrorData(true);
				});
		}
	}, [loggedIn]);

	useEffect(() => {
		setErrorData(false);
		if (loggedIn && localStorage.getItem('token')) {
			MainApi
				.getCards()
				.then((res) => {
					setCards(res);
				})
				.catch((err) => {
					console.log(err);
					setErrorData(true);
				});
		}
	}, [loggedIn]);

  function handleSearch(cards) {
    console.dir(cards);
    const cardsArray = cards.filter((card) => {
      console.dir(card);
      if (card.name.toLowerCase().includes(cardInput.toLowerCase())) {
        return card;
      }
    });
    setCardsArray(cardsArray);
  }

  const handleLogin = async ({ email, password }) => {
		setPreloader(true);
		setErrorData(false);
		return MainApi
			.authorize(email, password)
			.then((data) => {
				if (data.token) {
					localStorage.setItem('token', data.token);
					setLoggedIn(true);
          // console.log(currentUser.role);
          // currentUser.role === 'Admin' ? (navigate('/profile')) : (navigate('/'))
					navigate('/');
				}
			})
			.catch((err) => {
				unauthorized(err);
				setErrorData(true);
				console.log(err, ERROR_LOGIN_TEXT);
			})
			.finally(() => {
				setPreloader(false);
			})
	};

  const handleRegister = async (name, email, password) => {
		setPreloader(true);
		setErrorData(false);
		return MainApi
			.register(name, email, password)
			.then((res) => {
					handleLogin({email, password});
			})
			.catch((err) => {
				setErrorData(true);
				console.log(err, ERROR_REGISTER_TEXT);
			})
			.finally(() => {
				setPreloader(false);
			})
	};

	const handleAddCard = async (data) => {
		setPreloader(true);
		setErrorData(false);
		return MainApi
			.postCards(data)
			.then((res) => {
					setCards([...cards, res.data]);
			})
			.catch((err) => {
				setErrorData(true);
				console.log(err);
			})
			.finally(() => {
				setPreloader(false);
			})
	};

  const handleUpdateUser = async (name, email) => {
		setPreloader(true);
		setErrorData(false);
		MainApi.editProfile(name, email)
			.then((res) => {
				setCurrentUser(res);
				// navigate('/movies');
			})
			.catch((err) => {
				setErrorData(true);
				console.log(err, ERROR_REGISTER_TEXT);
			})
			.finally(() => {
				setPreloader(false);
			})
	};

  const unauthorized = (err) => {
		if (err === Error_401) {
			handleSignOut();
		}
	}

  const handleSignOut = () => {
		localStorage.removeItem('token');
		localStorage.removeItem('cardInput');
		localStorage.removeItem('cardsSwitch');
		localStorage.removeItem('cardsArray');
		navigate('/');
		setLoggedIn(false);
		setCardsArray([]);
		setCardInput([]);
	}

  const authentication = async (token) => {
		setErrorData(false);
		MainApi
			.getUserInfo(token)
			.then((res) => {
				if (res) {
					setLoggedIn(true);
				}
			})
			.catch((err) => {
				setLoggedIn(false);
				setErrorData(true);
				console.log(err);
			});
	};

  return (

    <CurrentUserContext.Provider value={currentUser}>
	    <div className="App">
        <Header
                loggedIn={loggedIn}
                cardsArray={cardsArray}
                setCardsArray={setCardsArray}
                cardInput={cardInput}
                setCardInput={setCardInput}
                onSearch={handleSearch}
                cards={cards}
                preloader={preloader}
                setPreloader={setPreloader}
              />
	      <Routes>
	        <Route path="/" element={
	          <>

	            <Main
                cards={cards}
                setCards={setCards}
                preloader={preloader}
								products={products}
              />

	          </>
	        }
					/>


        <Route path="/about" element={
          <>
            <About />

          </>
        }
        />
        <Route path="/delivery" element={
          <>
            <Delivery />

          </>
        }
        />
        <Route path="/contacts" element={
          <>
            <Contacts />

          </>
        }
        />
        <Route path="/cart" element={
          <>
            <Cart />

          </>
        }
        />
        <Route path="/order" element={
          <ProtectedRoute
				      loggedIn={loggedIn} >
				      <Order

				      />
						</ProtectedRoute>
        }
        />
        <Route path="/order-received" element={
          <ProtectedRoute
				      loggedIn={loggedIn} >
				      <OrderReceived

				      />
						</ProtectedRoute>
        }
        />
        {/* <Route path={`/product/${cards.id}`} element={ */}
				<Route path={`/product/:productId`} element={
          <>
            <Product
              cards={cards}
              setCards={setCards}
              products={products}
              preloader={preloader}
            />

          </>
        }
        />

				<Route path="/shoe" element={
          <>
            <CardList
              preloader={preloader}
							cards={cards}
              setCards={setCards}
							products={products}
							category="shoes"
            />
          </>
        }
        />

        <Route path="/accessorie" element={
          <>
            <CardList
              preloader={preloader}
							cards={cards}
              setCards={setCards}
							products={products}
							category="accessories"
            />
          </>
        }
        />

        <Route path="/shoe-care" element={
          <>
            <Shoecare />
          </>
        }
        />

        <Route path="/profile" element={
			      <ProtectedRoute
				      loggedIn={loggedIn} >
				      <Profile
					      onUpdateUser={handleUpdateUser}
					      onSignOut={handleSignOut}
					      preloader={preloader}
					      errorData={errorData}
					      loggedIn={loggedIn}
								onAddCard={handleAddCard}
				      />
						</ProtectedRoute>
						}

		      />
		      <Route path="/signin" element={
			      loggedIn ? (
				      <Navigate to='/profile' />
				    ) : (
					    <Login
						    onLogin={handleLogin}
					      preloader={preloader}
						    errorData={errorData}
					    />
			      )}
		      />
		      <Route path="/signup" element={
			      loggedIn ? (
				      <Navigate to='/' />
			      ) : (
				      <Register
					      onRegister={handleRegister}
					      preloader={preloader}
					      errorData={errorData}
				      />
			      )
					}
		      />

        <Route path="/*" element={<Error />} />

      </Routes>

      <Footer />

    </div>
    </CurrentUserContext.Provider>
  );
}