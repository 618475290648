import React, { useState } from 'react';
import {Link} from "react-router-dom";

export default function Order() {
	
	const [filter, setFilter] = useState('on');
	const [pay, setPay] = useState('');
	
	function handleFilterChange(e) {
		setFilter(e.target.value);
		if (filter === 'on') {
			setFilter('off');
		} else {
			setFilter('on');
		}
	}
	
	function handleFilterPayChange(e) {
		setPay(e.target.value);
		if (pay === 'on') {
			setPay('off');
		} else {
			setPay('on');
		}
		// onSearch();
	}
	
	return (
		
		<section className="cart">
			<h1 className="cart__caption cart__caption-order">Оформление заказа</h1>
			<form className="order__list">
				<div className="order__details">
				{/*	тут данные покупателя*/}
					<h3 className="order__title">Данные покупателя</h3>
					<div className="order__input-box">
						<input
							className="order__input"
							type="text"
							placeholder="Имя"
						/>
						<input
							className="order__input"
							type="text"
							placeholder="E-mail"
						/>
						<input
							className="order__input"
							type="text"
							placeholder="Телефон"
						/>
					</div>
					
				</div>
				<div className="order__info">
				{/*	тут инфа о заказе*/}
					<div>
						<h3 className="order__title order__title-order">Ваш заказ:</h3>
						<h3 className="order__title">№ 2023001-d</h3>
					</div>
					<div className="order__info-centr">
						<div className="order__info-left">
							<h3 className="order__caption">Товар</h3>
							<p className="order__product">Обыкновенный</p>
							{/*<p className="order__product">неОбыкновенный</p>*/}
							{/*<p className="order__product">простой</p>*/}
							<h4 className="order__caption order__caption-bold">Итого:</h4>
						</div>
						<div className="order__info-right">
							<h3 className="order__caption">Всего</h3>
							<p className="order__product">3000 ₽</p>
							{/*<p className="order__product">5000 ₽</p>*/}
							{/*<p className="order__product">1000 ₽</p>*/}
							<h4 className="order__caption order__caption-bold">9000 ₽</h4>
						</div>
					</div>
				</div>
				<div className="order__radio">
					<div className="order__checkbox">
						<label className="order__title order__radio-pay">Самовывоз</label>
						<label className="switch">
							<input
								className="switch__input"
								type='checkbox'
								name='filter'
								onChange={handleFilterChange}
							/>
							<span className="switch__slider"></span>
						</label>
						<label className="order__title order__radio-pay">Доставка</label>
					</div>
					<div className={`order__addres ${filter === 'off' ? '' : 'active'}`}>
					{/*	адрес получателя*/}
						<h3 className="order__title">Адрес получателя</h3>
						<input
							className="order__input"
							type="text"
							placeholder="Город"
						/>
						<input
							className="order__input"
							type="text"
							placeholder="Улица"
						/>
						<input
							className="order__input"
							type="text"
							placeholder="Дом"
						/>
						<input
							className="order__input"
							type="text"
							placeholder="Квартира"
						/>
					</div>
					<div className={`order__addres ${filter === 'on' ? '' : 'active'}`}>
					{/*	адрес офиса*/}
						<h3 className="order__title">Адрес магазина</h3>
						<input
							className="order__input"
							type="text"
							disabled={true}
							placeholder="г. Екатеринбург"
						/>
						<input
							className="order__input"
							type="text"
							disabled={true}
							placeholder="ул. Антона Валека"
						/>
						<input
							className="order__input"
							type="text"
							disabled={true}
							placeholder="д. 15"
						/>
						<input
							className="order__input"
							type="text"
							disabled={true}
							placeholder="офис 325"
						/>
					</div>
				</div>
				<div className="order__radio">
				{/*	способ оплаты*/}
					<h3 className="order__title">Способ оплаты</h3>
						<div className="order__checkbox">
							<label className="order__radio-pay-text">Оплата наличными</label>
							<label className="switch">
								<input
									className="switch__input"
									type='checkbox'
									name='pay'
									onChange={handleFilterPayChange}
								/>
								<span className="switch__slider"></span>
							</label>
							<label className="order__radio-pay-text">Оплата переводом</label>
						</div>
						
						{/*<div className="order__checkbox">*/}
						{/*	<label className="order__title order__radio-pay">Оплата наличными</label>*/}
						{/*	<label className="switch">*/}
						{/*		<input*/}
						{/*			className="switch__input"*/}
						{/*			type='checkbox'*/}
						{/*			name='pay'*/}
						{/*			onChange={handleFilterPayChange}*/}
						{/*		/>*/}
						{/*		<span className="switch__slider"></span>*/}
						{/*	</label>*/}
						{/*	<label className="order__title order__radio-pay">Оплата переводом</label>*/}
						{/*	/!*<label className="order__title order__radio-label">{pay === 'on' ? 'Оплата наличными' : 'Оплата переводом'}</label>*!/*/}
						{/*</div>*/}
						
					{pay === 'off' ?
							<form action="https://www.tinkoff.ru/rm/gatalskiy.dmitriy1/RicAz9191/" method="get" target="_blank">
								<button className="product__contact">Разместить заказ</button>
						</form> :
						(pay === 'on' ?
							<Link to="/order-received">
								<button className="product__contact">Разместить заказ</button>
							</Link> :
							<button className="product__contact product__button" disabled={true}>Разместить заказ</button>
						)
					}

					{/*<form action="https://wa.me/79956776572" method="get" target="_blank">*/}
					{/*	<button className="product__contact cart__button">Разместить заказ</button>*/}
					{/*</form>*/}
					{/*<Link action="https://wa.me/79956776572" method="get" target="_blank">*/}
					{/*	<button className="product__contact cart__button">Разместить заказ</button>*/}
					{/*</Link>*/}
					
				</div>
				<div className="order__radio">
				{/*	комментарии*/}
					<h3 className="order__title">Комментарии</h3>
					<input
						className="order__input"
						type="text"
						// checked={value == '1' ? true : false}
						// onChange={chengeValue}
					/>
				</div>
			</form>
		</section>
	
	)
}